import React from 'react';
import { Link } from "react-router-dom";
import { MDBCollapse } from "mdbreact";
import { connect } from "react-redux";

import logo from '../assets/logo.png';
import history from "../history";
import { showConfirmationModal, hideConfirmationModal } from "../actions/confirmation";
import { resetRestaurants } from "../actions/restaurants";
import messages from "../locales/messages";
import { locale } from "../constantes/constantes";
import { resetLocalStorage } from "../constantes/helpers";
import { FormattedMessage } from 'react-intl';

class SideBar extends React.Component {

    constructor(props) {
        super(props);
        switch (this.props.route) {
            case "/settings/profile":
            case "/settings/flavour":
            case "/settings/password":
                this.state = { collapsed: true };
                break;
            default:
                this.state = { collapsed: false };
        }
    }

    handleLogout = (e) => {
        const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];
        e.preventDefault();
        this.props.showConfirmationModal(
            msg['confirm.disconnect'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.resetRestaurants();
                this.props.hideConfirmationModal();
            },
            () => {
                this.props.hideConfirmationModal();
            }
        );
    };

    handleCollapse = () => {
        switch (this.props.route) {
            case "/settings/profile":
            case "/settings/flavour":
            case "/settings/password":
                break;
            default:
                this.setState({ collapsed: !this.state.collapsed })
        }
    };

    handleHome = () => {
        resetLocalStorage();
    };

    render() {
        return (
            <div id="sidebar">
                <div className="sidebar-logo">
                    <img alt="" src={logo} style={{ width: '150px' }} />
                </div>
                <div className="menu" style={{ fontFamily: 'Avenir Book' }}>
                    <Link className={this.props.route === "/home" ? 'active' : ''} to="/" onClick={this.handleHome}>
                        <span >
                            <span className="menuSpan"><i class="fas fa-home"></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.Home'/></span>
                        </span>
                    </Link>
                    <Link className={this.props.route === "/cash" ? 'active' : ''} to="/cash">
                        <span >
                        <span className="menuSpan"><i class="fas fa-dollar-sign" ></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.TymeCash'/></span>
                        </span>
                    </Link>
                    <Link className={this.props.route === "/payment" ? 'active' : ''} to="/payment">
                        <span>
                        <span className="menuSpan"><i class="fas fa-credit-card" ></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.payment-method'/></span>
                        </span>
                    </Link>
                    <Link className={this.props.route === "/orders" ? 'active' : ''} to="/orders">
                        <span>
                        <span className="menuSpan"> <i class="fas fa-shopping-cart"></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.order-history'/></span>
                        </span>
                    </Link>
                    <Link to="#" onClick={this.handleCollapse}>
                        <span>
                        <span className="menuSpan"><i class="fas fa-cog"></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.settings'/></span>
                        </span>
                    </Link>
                    <MDBCollapse className="ml-5" isOpen={this.state.collapsed} style={{ transition: "none" }}>
                        <Link className={this.props.route === "/settings/profile" ? 'active' : ''}
                            to="/settings/profile"><span><FormattedMessage id='sidebar.edit-profile'/></span></Link>
                        <Link className={this.props.route === "/settings/flavour" ? 'active' : ''}
                            to="/settings/flavour"><span><FormattedMessage id='sidebar.flavor-profile'/></span></Link>
                        <Link className={this.props.route === "/settings/password" ? 'active' : ''}
                            to="/settings/password"><span><FormattedMessage id='sidebar.change-password'/></span></Link>
                    </MDBCollapse>
                    <Link onClick={this.handleLogout} to="/logout">
                        <span>
                        <span className="menuSpan"><i class="fas fa-sign-out-alt"></i></span>
                            <span className="ml-3"><FormattedMessage id='sidebar.logout'/></span>
                        </span>
                    </Link>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    list: state.restaurant.list
});

export default connect(mapStateToProps, { showConfirmationModal, hideConfirmationModal, resetRestaurants })(SideBar);
