import {FETCH_RESTAURANTS, UPDATE_RESTAURANTS_PROP, NO_RESTAURANTS, RESET_RESTAURANTS} from '../actions/types'

let initialState = {
    list: [],
    loading: false,
    data: {},
    message: ''
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_RESTAURANTS:
            return {...state, list: action.payload};
        case NO_RESTAURANTS:
            return {...state, message: action.message};
        case RESET_RESTAURANTS:
            return {...state, list: []};
        case UPDATE_RESTAURANTS_PROP:
            return {...state, [action.payload.prop]: action.payload.value};
        default:
            return state
    }
};