import axios from "axios";
import history from "../history";
import {baseUrl} from "../constantes/constantes";
import {CONFIRM_ORDER, CANCEL_ORDER, ORDER_SUCCESS, UPDATE_ORDER_RESPONSE_PROP} from './types'

export const confirmOrder = data => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/order/${data.id}/confirm`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${data.token}`},
    };
    dispatch(updateProp({prop: 'loading', value: true}))
    axios(config)
        .then(res => {
                dispatch({
                    type: CONFIRM_ORDER,
                    payload: res.data.data
                })
                localStorage.removeItem('orderId');
                history.push("/confirmationSuccess");
            }
        )
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    localStorage.clear();
                    history.push('/login');
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(
            res => {
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        );
}
export const cancelOrder = data => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/order/${data.id}/cancel`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${data.token}`},
    };
    dispatch(updateProp({prop: 'loading', value: true}))
    axios(config)
        .then(res => {
                dispatch({
                    type: CANCEL_ORDER,
                    payload: res.data.data
                })
            }
        )
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    localStorage.clear();
                    history.push('/login');
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(
            res => {
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        );

}
export const sendSnack = (data, token) => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/favourite-snack`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`},
        data: data
    };
    dispatch(updateProp({prop: 'loading', value: true}))
    axios(config)
        .then(res => {
                dispatch({
                    type: ORDER_SUCCESS,
                })
                let user=JSON.parse(localStorage.getItem('user'))
                user.favorite_snack="pizza" 
                localStorage.setItem('user',JSON.stringify(user))
            }
        )
        .catch((err) => {
            switch (err.response) {
                case 401:
                    localStorage.clear();
                    history.push('/login');
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(
            res => {
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        );
}

export const updateProp = ({prop, value}) => {
    return {
        type: UPDATE_ORDER_RESPONSE_PROP,
        payload: {prop, value}
    }
};