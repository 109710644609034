import React from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";

import EditImageModal from './EditImageModal';
import Spinner from '../common/Spinner';
import MessageModal from '../common/MessageModal';
import {fetchProfile, updateProfileProp, updateProfile, resetProfileForm} from "../../actions/profile";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {formatPhoneNumberUS, validateEmail} from "../../constantes/helpers";
import {locale} from "../../constantes/constantes";
import messages from "../../locales/messages";

class EditProfile extends React.Component {

    state = {
        first_name_error: '',
        last_name_error: '',
        email_error: '',
        birth_date_error: '',
        phone_number_error: '',
        city_id_error: '',
    };

    resetForm(e) {
        e.preventDefault();
        this.props.resetProfileForm();
    }

    onSubmitForm = (e) => {
        e.preventDefault();

        this.props.updateProfileProp({prop: 'email_error', value: ''});

        const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

        let {first_name, last_name, city_id, phone_number, gender, birth_date, email} = this.props;

        this.setState({first_name_error: '', last_name_error: '', email_error: '', birth_date_error: '', phone_number_error: '', city_id_error: ''});

        first_name = first_name.trim();
        last_name = last_name.trim();
        email = email.trim();

        phone_number = phone_number.split('-').join('');

        if (phone_number.includes('+')) {
            phone_number = phone_number.substr(2);
        }

        let obj = {};
        if (!first_name.length) {
            obj.first_name_error = msg['settings.required_first_name'];
        }

        if (!last_name.length) {
            obj.last_name_error = msg['settings.required_last_name'];
        }

        if (!email.length) {
            obj.email_error = msg['settings.required_email'];
        }

        if (email && !validateEmail(email)) {
            obj.email_error = msg['settings.valid_email'];
        }

        /*if (!birth_date) {
            obj.birth_date_error = msg['settings.required_birth_date'];
        }*/

        if (!phone_number) {
            obj.phone_number_error = msg['settings.required_phone_number'];
        }

        if (phone_number && !phone_number.match(/\d/g)) {
            obj.phone_number_error = msg['settings.valid_phone_number'];
        } else {
            if (phone_number && phone_number.match(/\d/g).length !== 10) obj.phone_number_error = msg['settings.valid_phone_number'];
        }

        if (!city_id) {
            obj.city_id_error = msg['settings.required_city'];
        }

        if (Object.keys(obj).length) {
            this.setState(obj);
        } else {

            const user = JSON.parse(localStorage.getItem('user'));
            const formData = new FormData();
            formData.append('first_name', first_name);
            formData.append('last_name', last_name);
            formData.append('city_id', city_id);
            formData.append('phone_number', phone_number);
            formData.append('email', email);
            formData.append('gender', gender);
            if (birth_date) formData.append('birth_date', birth_date);
            if (this.props.picture_file) formData.append('profile', this.props.picture_file);

            this.props.updateProfile(formData, user.token);

        }
    };

    renderCities() {
        return this.props.cities.map(city => <option key={city.id} value={city.id}>{city.name}</option>);
    }

    onChangeImageClicked = () => {
        this.props.updateProfileProp({prop: 'showEditImage', value: true});
    };

    hideEditImageModal = () => {
        this.props.updateProfileProp({prop: 'showEditImage', value: false});
    };

    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        this.props.fetchProfile(user);
    }

    handleDateChange = (date) => {
        if (date) this.props.updateProfileProp({prop: 'birth_date', value: date.getTime() / 1000});
    };

    render() {
        const {email_error} = this.props;
        return (
            <div className="container-fluid settings-form" style={{padding: "8%"}}>
                <form onSubmit={this.onSubmitForm} className="from-container">

                    <div className="mb-5">
                        <div className="mb-3">
                            <h3 className="order-text-blue"><FormattedMessage id="settings.edit_profile"/></h3>
                        </div>
                        <div className="rounded-profile-image" style={{backgroundImage: `url(${this.props.profile.length ? this.props.profile : ''})`}} >
                            <div className="rounded-profile-image-content" onClick={this.onChangeImageClicked}>
                                <FormattedMessage id="settings.change_image"/>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <FormattedMessage id="global.first_name">
                                {msg => (
                                    <input value={this.props.first_name}
                                           onChange={e => this.props.updateProfileProp({prop: 'first_name', value: e.target.value})}
                                           className={`form-control ${this.state.first_name_error.length ? 'is-invalid' : ''}`}
                                           placeholder={msg}/>
                                )}
                            </FormattedMessage>
                            <div className="invalid-feedback">
                                {this.state.first_name_error}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <FormattedMessage id="global.last_name">
                                {msg => (
                                    <input value={this.props.last_name}
                                           onChange={e => this.props.updateProfileProp({prop: 'last_name', value: e.target.value})}
                                           className={`form-control ${this.state.last_name_error.length ? 'is-invalid' : ''}`}
                                           placeholder={msg}/>
                                )}
                            </FormattedMessage>
                            <div className="invalid-feedback">
                                {this.state.last_name_error}
                            </div>
                        </div>
                    </div>

                    <div className="row settingsContainer" >
                        <div className="form-group col-md-6">
                            <select value={this.props.gender} onChange={e => this.props.updateProfileProp({prop: 'gender', value: e.target.value})} className="form-control">
                                <option value="0">Male</option>
                                <option value="1">Female</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <DatePicker
                                className={`date-picker form-control ${this.state.birth_date_error.length ? 'is-invalid' : ''}`}
                                selected={this.props.birth_date ? new Date(this.props.birth_date * 1000) : ''}
                                onChange={this.handleDateChange}
                                showYearDropdown
                                dateFormatCalendar="MMMM"
                                scrollableYearDropdown
                                yearDropdownItemNumber={50}
                                filterDate={(date) => {
                                    return new Date() > date;
                                }}
                                maxDate={new Date()}
                                placeholderText='Birthday'
                            />
                            <div className="invalid-feedback">
                                {this.state.birth_date_error}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <input value={this.props.email} onChange={e => this.props.updateProfileProp({prop: 'email', value: e.target.value})} className={`form-control ${this.state.email_error.length || email_error ? 'is-invalid' : ''}`} placeholder="Email"/>
                            <div className="invalid-feedback">
                                {this.state.email_error || email_error}
                            </div>
                        </div>
                        <div className="form-group col-md-6">
                            <input type="tel" maxLength="15" value={formatPhoneNumberUS(this.props.phone_number)} onChange={e => this.props.updateProfileProp({prop: 'phone_number', value: e.target.value})} className={`form-control ${this.state.phone_number_error.length ? 'is-invalid' : ''}`} placeholder="Phone Number"/>
                            <div className="invalid-feedback">
                                {this.state.phone_number_error}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="form-group col-md-6">
                            <select className="form-control">
                                <option value="US">United States(+1)</option>
                            </select>
                        </div>
                        <div className="form-group col-md-6">
                            <select value={this.props.city_id} onChange={e => this.props.updateProfileProp({prop: 'city_id', value: e.target.value})} className={`form-control ${this.state.city_id_error.length ? 'is-invalid' : ''}`}>
                                {this.renderCities()}
                            </select>
                            <div className="invalid-feedback">
                                {this.state.city_id_error}
                            </div>
                        </div>
                    </div>

                    <div className="content-spaced-between" style={{marginTop: "4%"}}>
                        <button className="pink-button">
                            <FormattedMessage id="global.save_changes"/>
                        </button>
                        <button className="grey-button" onClick={this.resetForm.bind(this)}>
                            <FormattedMessage id="global.cancel"/>
                        </button>
                    </div>
                </form>

                <Spinner loading={this.props.loading}/>

                <EditImageModal show={this.props.showEditImage} hide={this.hideEditImageModal} />

                <MessageModal show={this.props.message} message={this.props.message} handleCloseModal={() => this.props.updateProfileProp({prop: 'message', value: ''})} />
            </div>
        );
    }
}

const mapStateToProps = state => {
    const {first_name, last_name, gender, birth_date, email, phone_number, city_id, cities, profile, defaultState, showEditImage, loading, picture_file, message, email_error} = state.profile;
    return {first_name, last_name, gender, birth_date, email, phone_number, city_id, cities, profile, defaultState, showEditImage, loading, picture_file, message, email_error};
};

export default connect(mapStateToProps, {fetchProfile, updateProfileProp, updateProfile, resetProfileForm})(EditProfile);