import React, {Component} from 'react';
import MerchantRework from "./MerchantRework";
import MerchantProductRework from "./MerchantProductRework";
import PlaceOrderRework from "./PlaceOrderRework";
import {connect} from "react-redux";

class OrderRework extends Component {

    renderComponent = () => {
        const {step} = this.props;
        switch (step) {
            case 2:
                return <MerchantProductRework/>;
            case 3:
                return <PlaceOrderRework/>;
            case 1:
            default:
                return <MerchantRework/>;
        }
    };

    render() {
        return (
            <div>
                {this.renderComponent()}
            </div>
        );
    }

}

const mapStateToProps = state => {
    const {step} = state.schedule;
    return {step};
};

export default connect(mapStateToProps, {})(OrderRework);