import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom'
import Spinner from '../components/common/Spinner'

/**** */

import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
/**** */
import Background from '../assets/background.png'
import logo from '../assets/logo.png'
import { loginUser } from '../actions/authentication';
import { googleClientId } from '../constantes/constantes'
import { validateEmail } from '../constantes/helpers'
import { FormattedMessage } from 'react-intl';

var loginStyle = {
    backgroundImage: `url(${Background})`,
};

class Login extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            redirect: false,
            gid: '',
            errorMail: false,
            invalidMail: false,
            errorPsw: false,
        }
    }

    handlechange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        const user = {
            email: this.state.email,
            password: this.state.password,
        }
        this.setState({
            errorMail: false,
            invalidMail: false,
            errorPsw: false,
        })

        if (this.state.email === "") {
            this.setState({ errorMail: !this.state.errorMail })
        } else if (!validateEmail(this.state.email)) {
            this.setState({ invalidMail: !this.state.invalidMail })
        } else if (this.state.password === "") {
            this.setState({ errorPsw: !this.state.errorPsw })
        } else {
            this.props.loginUser(user);
        }

    }

    responseGoogle = (response) => {
        this.setState({gid:response.googleId})
        const user = new FormData()
        user.append('gid', response.googleId)
        this.props.loginUser(user)
    
    }

    componentDidMount() {
        //test if user exist and have token and have fistname and lastname
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user);
        if (myuser && myuser.token && myuser.first_name && myuser.last_name) {
            this.props.history.push('/');
        }
        localStorage.removeItem('googleResponse')
        localStorage.removeItem('user')
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.auth.isAuthenticated) {
            this.props.history.push('/')
        }
    }

    googleFailureAlert = () => {
        console.log('error')
    }

    googleSuccessAlert = () => {
        alert('no error')
    }

    render() {
        const { errors } = this.props;
        return (
            <div className="loginPage" style={loginStyle}>
                <Spinner loading={this.props.auth.loading} />
                <div className=" loginPage-container">
                    <div className=" loginPage-header">
                        <img src={logo} alt="" />
                    </div>
                    <div className="loginPage-text">
                        <p>ENTERPRISE FOOD PROGRAM</p>
                    </div>
                    <div className=" loginPage-form">
                        <form
                            onSubmit={this.handleSubmit}
                        >
                            <div>
                                <input
                                    type="email"
                                    className={classnames('loginPage-box', {
                                        'is-invalid': errors && errors.message
                                    })}
                                    placeholder="Email"
                                    name="email"
                                    onChange={this.handlechange}
                                />
                            </div>
                            {this.state.invalidMail ?
                                <div className="registerError"><FormattedMessage id='login.valid.email'/></div> : <div></div>}
                            {this.state.errorMail ? <div className="registerError"> <FormattedMessage id='complete.this.field'/></div> :
                                <div></div>}
                            <div>
                                <input
                                    type="password"
                                    className={classnames('loginPage-box', {
                                        'is-invalid': errors && errors.message
                                    })}
                                    placeholder="Password"
                                    name="password"
                                    onChange={this.handlechange}
                                />
                            </div>
                            {errors && errors.password && (<div className="registerError">{errors.password}</div>)}
                            {this.state.errorPsw && (<div className="registerError"><FormattedMessage id='complete.this.field'/></div>)}
                            {errors && errors.message && !this.state.gid&& (
                                <div className="LoginError"><FormattedMessage id='Email.login.error'/></div>)}
                            <div className="text-center mt-4">
                                <button className="loginPage-btn loginPage-signIn" type="submit"><FormattedMessage id="login.button"/></button>

                            </div>
                            <div className="loginPage-link">
                                <Link to="/forgetPassword"><FormattedMessage id='login.Forgot.your.password'/></Link>
                            </div>
                        </form>
                        <GoogleLogin

                            clientId={googleClientId}
                            render={renderProps => (
                                <button className="loginPage-btn loginPage-google"

                                    onClick={renderProps.onClick} disabled={renderProps.disabled}
                                >
                                 <i class="fab fa-google"></i><span> <FormattedMessage id='login.google.button'/></span>
                                </button>
                            )}
                            onSuccess={(response) => {
                                this.responseGoogle(response)
                            }}
                            onFailure={() => {
                                this.googleFailureAlert()
                            }}

                        >
                          <i class="fab fa-google"></i>
                            <span> <FormattedMessage id='login.google.button'/></span>
                        </GoogleLogin>
                        {errors && errors.message &&this.state.gid&& (
                            <div className="LoginError GoogleLoginError"><FormattedMessage id='Google.login.error' />
                            </div>)}
                        <div className="loginPage-link">
                            <Link to="/registerChoice"><FormattedMessage id='login.account.question'/></Link>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
};

Login.propTypes = {
    loginUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    errors: state.errors.errors,
})

export default connect(mapStateToProps, { loginUser })(Login)


