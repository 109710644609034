import React, { Component } from 'react'
import { MDBCollapse } from "mdbreact";
import { formatMoney } from '../../constantes/helpers'
import { firstToUpperCase } from '../../constantes/helpers'


import MerchantProductModifierItem from './MerchantProductModifierItem'
import MerchantProductModifierItemMultiple from './MerchantProductModifierMultiple'


class MerchantProductModifier extends Component {
    state = {
        collapseID: "",
        // open: false,
    }

    handleRadio = (item, e) => {
        let finalOrderTab = JSON.parse(localStorage.getItem('finalOrder'))
        let productIndex = localStorage.getItem('productIndex')
        let currentId = JSON.parse(localStorage.getItem('currentProductId'))

        let current_modifierClass_id = item.pivot.group_id
        let obj = { [e.target.name]: e.target.checked ? e.target.id : null, chosen: current_modifierClass_id };
        this.setState(obj);
        let stateRadioTab = JSON.parse(localStorage.getItem('stateRadioTab'))
        if (e.target.checked) {
            if (!stateRadioTab) {
                let stateRadioTab = []
                stateRadioTab.push(obj)
                localStorage.setItem('stateRadioTab', JSON.stringify(stateRadioTab))
                if (finalOrderTab && productIndex) {
                    let productId = finalOrderTab[productIndex].id
                    if (productId == currentId) {
                        finalOrderTab[productIndex].stateRadioButtons = stateRadioTab
                        localStorage.setItem('finalOrder', JSON.stringify(finalOrderTab))
                    }
                }
            } else {
                let filtered = stateRadioTab.filter(el => el.chosen != current_modifierClass_id);
                filtered.push(obj)
                localStorage.setItem('stateRadioTab', JSON.stringify(filtered))
                if (finalOrderTab && productIndex) {
                    let productId = finalOrderTab[productIndex].id
                    if (productId == currentId) {
                        finalOrderTab[productIndex].stateRadioButtons = filtered
                        localStorage.setItem('finalOrder', JSON.stringify(finalOrderTab))
                    }
                }
            }
        } else if (!e.target.checked) {
            let filtered = stateRadioTab.filter(el => el[e.target.name] == null);
            localStorage.setItem('stateRadioTab', JSON.stringify(filtered))
            if (finalOrderTab && productIndex) {
                let productId = finalOrderTab[productIndex].id
                if (productId == currentId) {
                    finalOrderTab[productIndex].stateRadioButtons = filtered
                    localStorage.setItem('finalOrder', JSON.stringify(finalOrderTab))
                }
            }
        }
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : ""
        }));
    }
    openCollapse = () => {
        const { item } = this.props
        let modifiersTab = JSON.parse(localStorage.getItem('modifiersTab'))
        if(modifiersTab){modifiersTab.map(el => {
            if (el.chosen == item.id) {
                this.setState({
                    collapseID: true
                })
            }
        })}
    }
    modifierHandle = (item) => {
        let current_modifierClass_id = item.pivot.group_id
        let modifiersTab = JSON.parse(localStorage.getItem('modifiersTab'))
        let modifier = {
            name: item.name,
            price: item.pivot.price,
            id: item.id,
            force_tax: item.pivot.force_tax,
            chosen: current_modifierClass_id,
        }
        if (this.state[`modifier${item.pivot.group_id}`] === `radio_${item.id}`) {
            let filteredTab = modifiersTab.filter(el => el.chosen != item.pivot.group_id)
            localStorage.setItem('modifiersTab', JSON.stringify(filteredTab))
        } else if (modifiersTab == null) {
            modifiersTab = []
            modifiersTab.push(modifier)
            localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
        }
        else {
            let filteredTab = modifiersTab.filter(el => el.chosen != item.pivot.group_id)
            filteredTab.push(modifier)
            localStorage.setItem('modifiersTab', JSON.stringify(filteredTab))
            localStorage.setItem('modifierClass_id', item.pivot.group_id)
        }

    }
    inputRender = () => {
        const { item } = this.props
        if (item.modifiers) {
            if (item.multiple === 1) {
                return item.modifiers.map((element, index) => <div className="colProduct">
                    <MerchantProductModifierItemMultiple key={index} item={element} />
                </div>)
            }
            else {
                return item.modifiers.map((element, index) => <div className="colProduct" key={index}>
                    {/* <MerchantProductModifierItem
                        key={index} item={element} handleChange={this.handleRadio}
                        checked={this.state[`modifier${element.pivot.group_id}`] === `radio_${element.id}`} /> */}

                    <div className="productItem custom-control custom-radio">
                        <input
                            type="checkbox"
                            className="form-check-input custom-control-input"
                            name={`modifier${element.pivot.group_id}`}
                            value={`${element.id}`}
                            onClick={() => this.modifierHandle(element)}
                            onChange={(e) => this.handleRadio(element, e)}
                            id={`radio_${element.id}`}
                            checked={this.state[`modifier${element.pivot.group_id}`] === `radio_${element.id}`}
                        />
                        <label className="form-check-label custom-control-label d-flex justify-content-between"
                            for={`radio_${element.id}`}>
                            <div className="productItem-title">{firstToUpperCase(element.name)}</div>
                            <div className="productItem-price"> {`$ ${formatMoney(element.pivot.price / 100)}`}</div>
                        </label>
                    </div>
                </div>)
            }

        }
    }
    componentDidMount() {
    
        const { item } = this.props
        const state = this.state;
        let finalOrderTab = JSON.parse(localStorage.getItem('finalOrder'))
 
        let productIndex = localStorage.getItem('productIndex')
        if (finalOrderTab && productIndex) {
            let productId = finalOrderTab[productIndex].id
            let modifiersTab = finalOrderTab[productIndex].modifiers
        //     /*i have setted the stateRadioTab because on when we reload the page it will be eliminated from the localstorage */
            let stateTab = finalOrderTab[productIndex].stateRadioButtons
            localStorage.setItem('stateRadioTab', JSON.stringify(stateTab))
            localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
       let currentId = JSON.parse(localStorage.getItem('currentProductId'))
            if (modifiersTab && stateTab && (productId == currentId)) {
                if (stateTab.length) {
                    let obj = {};
                    stateTab.forEach(element => {
                        obj = { ...obj, ...element }
                    });

                    this.setState({
                        ...state, ...obj
                    });
                }
            }
        }
        this.openCollapse()
    }

    render() {
     
        const { item } = this.props  
        return (
            <div>
                <div className="merchantBody-collapse-btn" onClick={this.toggleCollapse(`${item.id}`)} >
                    <div className={this.state.colorchange ? "merchantBody-collapse-category showed" : "merchantBody-collapse-category"}  >{firstToUpperCase(item.name)}</div>
                    <i className={this.state.collapseID ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down "}></i>
                </div>
                <MDBCollapse className="merchantBody-collapse" id={`${item.id}`} isOpen={this.state.collapseID}>
                    <div className="rowProduct">
                        {this.inputRender()}
                    </div>

                </MDBCollapse>
            </div>);
    }
}

export default MerchantProductModifier;