import {combineReducers} from 'redux';
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import orderReducer from './orderReducer';
import profileReducer from './profileReducer';
import restausResducer from './restausResducer';
import merchantReducer from './merchantReducer';
import productOrderReducer from './productOrderReducer'
import orderResponseReducer from './orderResponseReducer'
import forgetPasswordReducer from './forgetPasswordReducer';
import confirmReducer from './confirmReducer';
import scheduleReducer from './scheduleReducer';

export default combineReducers({
    errors: errorReducer,
    auth: authReducer,
    orders: orderReducer,
    profile: profileReducer,
    restaurant: restausResducer,
    merchant: merchantReducer,
    product: productOrderReducer,
    orderResponse: orderResponseReducer,
    forgetPassword: forgetPasswordReducer,
    confirm: confirmReducer,
    schedule: scheduleReducer
});