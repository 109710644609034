import {FETCH_ORDERS, SHOW_ORDER_DETAILS, HIDE_ORDER_DETAILS, FETCH_MORE_ORDERS, UPDATE_ORDERS_PROP, SHOW_HELP_MODAL, HIDE_HELP_MODAL, SEND_HELP_EMAIL_SUCCESS, SEND_HELP_EMAIL_ERROR} from '../actions/types';

const INITIAL_STATE = {
    list: {},
    loading: true,
    order: null,
    showDetails: false,
    showHelp: false,
    total: 0,
    currentPage: 1,
    message: '',
    last_page: 1
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case FETCH_ORDERS:
            return {...state, list: action.payload.list, total: action.payload.total, last_page: action.payload.last_page};
        case FETCH_MORE_ORDERS:
            let list = state.list;
            let newList = action.payload.list;

            Object.keys(newList).forEach(key => {
                if (key in list) {
                    list[key] = [...list[key], ...newList[key]]
                } else {
                    list[key] = newList[key];
                }
            });

            return {...state, list: list, currentPage: action.payload.currentPage, total: action.payload.total, last_page: action.payload.last_page};
        case SHOW_ORDER_DETAILS:
            return {...state, order: action.payload, showDetails: true};
        case HIDE_ORDER_DETAILS:
            return {...state, order: null, showDetails: false};
        case SHOW_HELP_MODAL:
            return {...state, order: action.payload, showHelp: true};
        case HIDE_HELP_MODAL:
            return {...state, order: null, showHelp: false};
        case SEND_HELP_EMAIL_SUCCESS:
            return {...state, order: null, showHelp: false, message: action.payload, loading: false};
        case SEND_HELP_EMAIL_ERROR:
            return {...state, order: null, showHelp: false, message: action.payload, loading: false};
        case UPDATE_ORDERS_PROP:
            return {...state, [action.payload.prop]: action.payload.value};
        default:
            return state;
    }
};