import { FETCH_MERCHANT, UPDATE_MERCHANT_PROP} from '../actions/types'

let initialState = {
    loading: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_MERCHANT:
            return { ...state, merchant: action.payload }
        case UPDATE_MERCHANT_PROP:
            return { ...state, [action.payload.prop]: action.payload.value };
        default:
            return state
    }
}