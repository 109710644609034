import React from 'react';
import axios from "axios";
import { baseUrl, locale } from "../../constantes/constantes";

import Spinner from '../common/Spinner';
import history from "../../history";
import MessageModal from "../common/MessageModal";
import messages from "../../locales/messages";
import { FormattedMessage } from "react-intl";
import { hideConfirmationModal, showConfirmationModal } from "../../actions/confirmation";
import { connect } from "react-redux";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

class FlavourProfile extends React.Component {

    state = {
        jwtToken: null,
        loading: false,
        flavours: [],
    };

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.checked ? parseInt(e.target.value) : null
        });
    };

    handleSubmit = e => {
        e.preventDefault();
        const keys = Object.keys(this.state).filter(key => (key !== 'jwtToken') && (key !== 'loading') && (key !== 'flavours') && (key !== 'message'));
        let array = [];

        keys.forEach(key => {
            if (this.state[key]) array.push(this.state[key])
        });

        this.setState({ loading: true });

        const formData = new FormData();
        array.forEach(id => formData.append('choices[]', id));

        let config = {
            method: 'post',
            url: `${baseUrl}/api/flavor`,
            timeout: 20000,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${this.state.jwtToken}` },
            data: formData
        };

        axios(config)
            .then(res => {
                this.setState({ loading: false, message: msg['success.update_profile'] });
            })
            .catch(err => {
                const { status } = err.response;
                switch (status) {
                    case 401:
                        this.showUnauthorizedModal();
                        break;
                    case 404:
                    default:
                        console.log(err)
                }
            });
    };

    renderFlavours() {
        return this.state.flavours.map(flavour => {
            return (
                <div className="mt-5" key={flavour.id}>
                    <span className="flavour-title">{flavour.title}</span>
                    <hr />
                    <div className="flavorRadios">
                        {flavour.options.map(option => {
                            return (
                                <div key={option.id} className="form-check  custom-control custom-checkbox ">
                                    <input
                                        type="checkbox" name={flavour.title}
                                        id={option.id} value={option.id}
                                        checked={this.state[flavour.title] === option.id}
                                        onChange={this.handleChange}
                                        className="form-check-input custom-control-input"
                                    />
                                    <label className="form-check-label custom-control-label d-flex justify-content-between" htmlFor={option.id}>{option.title}</label>
                                </div>
                            );
                        })}
                    </div>
                </div>
            );
        });
    }

    componentWillMount() {
        const user = localStorage.getItem('user');
        if (user) {
            const object = JSON.parse(user);
            this.setState({ jwtToken: object.token });
        }
    }

    showUnauthorizedModal = () => {
        this.setState({ loading: false });
        this.props.showConfirmationModal(
            msg['error.unauthorized'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.hideConfirmationModal();
            }, null);
    };

    componentDidMount() {
        let config = {
            method: 'get',
            url: `${baseUrl}/api/flavor`,
            timeout: 20000,
            headers: { "Content-Type": "application/json", "Authorization": `Bearer ${this.state.jwtToken}` },
        };

        this.setState({ loading: true });

        axios(config)
            .then(res => {
                const flavours = res.data.data;
                let object = {};

                flavours.forEach(flavour => {
                    const options = flavour.options;
                    object[flavour.title] = null;
                    options.forEach(option => {
                        if (option.chosen) {
                            object[flavour.title] = option.id;
                        }
                    });
                });

                this.setState({ flavours, ...object, loading: false });
            })
            .catch(err => {
                const { status } = err.response;
                switch (status) {
                    case 401:
                        this.showUnauthorizedModal();
                        break;
                    case 404:
                    default:
                        console.log(err)
                }
            });
    }

    render() {
        return (
            <div className="container-fluid" style={{ padding: "8% 0%" }}>
                <Spinner loading={this.state.loading} />
                <div className="flavour-form">
                    <form onSubmit={this.handleSubmit}>
                        <h5 className="text-center order-text-blue">
                            <FormattedMessage id="settings.flavour_title" />
                        </h5>

                        {this.renderFlavours()}

                        {this.state.flavours.length ? (
                            <div className="text-center">
                                <button style={{ backgroundColor: '#064485', color: '#ffff', boxShadow: 'none', fontFamily: 'Avenir Heavy', fontSize: '16px', textTransform: 'capitalize', padding:'8px 20px'  }} className="btn  mt-5 col-6">
                                    <FormattedMessage id="global.submit" />
                                </button>
                            </div>
                        ) : null}

                    </form>
                    <MessageModal show={this.state.message} message={this.state.message} handleCloseModal={() => this.setState({ message: '' })} />
                </div>
            </div>
        );
    }
}

export default connect(null, { showConfirmationModal, hideConfirmationModal })(FlavourProfile);