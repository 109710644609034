import {FETCH_PROFILE, UPDATE_PROFILE_PROP, FETCH_CITIES, UPDATE_PROFILE, RESET_PROFILE_FROM, UPDATE_PROFILE_IMAGE} from "./types";
import {baseUrl, locale} from "../constantes/constantes";
import axios from "axios";
import history from "../history";
import messages from "../locales/messages";
import {hideConfirmationModal, showConfirmationModal} from "./confirmation";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

export const fetchProfile = params => dispatch => {
    let config = {
        method: 'get',
        url: `${baseUrl}/api/profile`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${params.token}`},
    };

    dispatch(updateProfileProp({prop: 'loading', value: true}));

    axios(config)
        .then((res) => {
            const user = res.data.data;
            localStorage.setItem('user', JSON.stringify(user));

            dispatch({
                type: FETCH_PROFILE,
                payload: {...user, profile: baseUrl + '/' + user.profile, message: ''}
            });

            dispatch(fetchCities());

        })
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 404:
                default:
                    console.log(err)
            }
        });
};

export const fetchCities = () => dispatch => {
    axios.get(`${baseUrl}/api/city`)
        .then((res) => {
            const cities = res.data.data;

            dispatch({
                type: FETCH_CITIES,
                payload: cities
            });

        })
        .catch((err) => {
            //TODO
        })
        .finally(() => {
            dispatch(updateProfileProp({prop: 'loading', value: false}));
        });
};

export const updateProfile = (data, token) => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/profile`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`},
        data: data
    };

    dispatch(updateProfileProp({prop: 'loading', value: true}));

    axios(config)
        .then((res) => {
            const user = res.data.data;
            localStorage.setItem('user', JSON.stringify(user));

            dispatch({
                type: UPDATE_PROFILE,
                payload: {...user, profile: baseUrl + '/' + user.profile, message: msg['success.update_profile']}
            });

        })
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 422:
                    if (err.response.data.errors.email) {
                        dispatch(updateProfileProp({prop: 'email_error', value: err.response.data.errors.email[0]}));
                    }
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(() => {
            dispatch(updateProfileProp({prop: 'loading', value: false}));
        });
};

export const updateProfileProp = ({prop, value}) => {
    return {
        type: UPDATE_PROFILE_PROP,
        payload: {prop, value}
    };
};

export const updateProfileImage = (url, file) => {
    return {
        type: UPDATE_PROFILE_IMAGE,
        payload: {profile: url, picture_file: file}
    };
};

export const resetProfileForm = () => {
    return {
        type: RESET_PROFILE_FROM
    };
};

const showUnauthorizedModal = dispatch => {
    dispatch(updateProfileProp({prop: 'loading', value: false}));
    dispatch(showConfirmationModal(
        msg['error.unauthorized'],
        () => {
            localStorage.clear();
            history.push('/login');
            dispatch(hideConfirmationModal());
        }, null));
};