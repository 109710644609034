import React, { Component } from 'react';
import { formatMoney } from '../../constantes/helpers'
import { firstToUpperCase } from '../../constantes/helpers'

class SubcategorieItemProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    productHandle = () => {
        const { item } = this.props
        let directProduct = {
            title: item.title,
            description: item.description,
            groups: item.groups,
            id: item.id, 
            price:item.price
        }
        localStorage.setItem('directProduct', JSON.stringify(directProduct))
    }
    render() {
        const { item, handleChange} = this.props
        return (
            <div className="subcategoryProduct" >
                {item && <div className="custom-control custom-radio">
                    <input
                        type="radio"
                        className="form-check-input custom-control-input"
                        name="product"
                        value={`${item.id}`}
                        onChange={handleChange}
                        onClick={()=>this.productHandle(item.id)}
                        id={item.id}
                    />
                    <label className="productItem-subcategory-label form-check-label custom-control-label d-flex justify-content-between" for={item.id}>
                        <div className="productItem-title productItem-subcategory-title">{firstToUpperCase(item.title)}</div>
                        <div className="productItem-price"> {`$ ${formatMoney(item.price / 100)}`}</div>
                    </label>
                </div>}
            </div>);
    }
}

export default SubcategorieItemProduct;