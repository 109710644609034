import React, { Component } from 'react';
import { formatMoney } from '../../constantes/helpers'
import { firstToUpperCase } from '../../constantes/helpers'

class ProductItem extends Component {

    productHandle = (id) => {
        const { item } = this.props
        let directProduct = {
            title: item.title,
            description: item.description,
            groups: item.groups,
            id: item.id,
            price: item.price,
            tax_exempt: item.tax_exempt
        }
        if (document.getElementById(id).checked === true) {
            localStorage.setItem('directProduct', JSON.stringify(directProduct))
        } 
    }

    render() {
        const { item, handleChange} = this.props
        return (
            <div className="productItem custom-control custom-radio" >
                <input
                    type="radio"
                    className="form-check-input custom-control-input"
                    name="product"
                    value={item.id}
                    onChange={handleChange}
                    onClick={() => {this.productHandle(`radio_${item.id}`)}}
                    id={`radio_${item.id}`}
                    // checked={checkProductRadio==item.id}
                />
                <label className="form-check-label custom-control-label d-flex justify-content-between" for={`radio_${item.id}`}>
                    <div className="productItem-title">{firstToUpperCase(item.title)}</div>
                    <div className="productItem-price"> {`$ ${formatMoney(item.price / 100)}`}</div>
                </label>
            </div>
        );
    }
}

export default ProductItem;