import React from 'react';
import {connect} from "react-redux";
import moment from 'moment';
import {FormattedMessage} from "react-intl";

import {hideOrderDetails} from "../../actions/orders";
import {formatMoney} from "../../constantes/helpers";

const renderModifiers = modifiers => {
    return modifiers.map(modifier => {
        return (
            <div key={modifier.id} className="content-spaced-between" style={{paddingLeft: "20px"}}>
                <span> + {modifier.name}</span>
                <span>$ {formatMoney(modifier.price / 100)}</span>
            </div>
        );
    });
};

const renderProducts = products => {
    return products.map(product => {
        return (
            <div key={product.id}>
                <div className="content-spaced-between">
                    <span>{product.title}</span>
                    <span>{product.pivot.quantity} X $ {formatMoney(product.price / 100)}</span>
                </div>
                {renderModifiers(product.pivot.modifiers)}
            </div>
        );
    });
};

const renderStatus = order => {
    if (order.refund_reason) {
        return <div>{order.refund_reason}</div>
    }

    if (order.served_at) {
        return <div><FormattedMessage id='order.history-Successfully.Delivered'/></div>
    }

    return <div><FormattedMessage id='order.history-Waiting.for.Merchant'/></div>;
};

const DetailsModal = props => {
    const order = props.order;

    if (order) {
        return (
            <div className={`modal fade show ${props.showDetails ? 'modal-visible' : 'modal-hidden' }`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{fontFamily:'Avenir Medium'}}><FormattedMessage id="orders.receipt_order_no"/> {order.id}</h5>
                            <button onClick={() => props.hideOrderDetails()} type="button" className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="description-container">
                                <FormattedMessage id="orders.merchant"/>
                            </div>
                            <div>{order.merchant.name}</div>
                            <div className="description-container"><FormattedMessage id='order.history-date'/></div>
                            <div>{moment.unix(order.created_at).format("M/D/YY hh:mm a") }</div>
                            <div className="description-container">
                                <FormattedMessage id="orders.status"/>
                            </div>
                            {renderStatus(order)}
                            <div className="description-container">
                                <FormattedMessage id="orders.order_details"/>
                            </div>
                            {renderProducts(order.products)}
                            <div className="content-spaced-between" style={{marginTop: "20px"}}>
                                <FormattedMessage id="orders.subtotal"/>
                                <div>{`$ ${formatMoney(order.original_amount / 100)}`}</div>
                            </div>
                            <div className="content-spaced-between">
                                <span><FormattedMessage id='order.history-promo'/></span>
                                <span>-$ {formatMoney((order.tyme_offer_amount / 100)+(order.merchant_offer_amount/100))}</span>
                            </div>
                            <div className="content-spaced-between">
                                <span><FormattedMessage id='order.history-tax'/></span>
                                <span>$ {formatMoney(order.tax_amount / 100)}</span>
                            </div>
                            <div className="content-spaced-between"
                                 style={{fontSize: "18px", fontWeight: "bold", marginTop: "20px"}}>
                                <span><FormattedMessage id='order.history-total'/></span>
                                <span>
                                <div>{`$ ${formatMoney((order.amount / 100)+(order.tyme_amount / 100))}`}</div>
                            </span>
                            </div>
                            <div className="content-spaced-between">
                                <span><FormattedMessage id='order.history-card.payment'/></span>
                                <span>
                                <div>{`$ ${formatMoney(order.amount / 100)}`}</div>
                            </span>
                            </div>
                            <div className="content-spaced-between">
                                <span><FormattedMessage id='order.history-tymecash.payment'/></span>
                                <span>
                                <div>${order.status === 9 ?formatMoney(0):formatMoney(order.tyme_amount / 100)}</div>
                            </span>
                            </div>
                            <div className="content-spaced-between mt-4">
                                <span/>
                                <span>
                                <div>{order.amount ? `TymeCash to earn $ ${formatMoney((order.amount * 0.03) / 100)}` : null}</div>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return null;
};

const mapStateToProps = state => {
    const {order, showDetails} = state.orders;
    return {order, showDetails};
};

export default connect(mapStateToProps, {hideOrderDetails})(DetailsModal);