import {SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL} from "./types";

export const showConfirmationModal = (message, confirm, cancel) => {
    return {
        type: SHOW_CONFIRMATION_MODAL,
        payload: {message, confirm, cancel}
    };
};

export const hideConfirmationModal = () => {
    return {
        type: HIDE_CONFIRMATION_MODAL
    };
};