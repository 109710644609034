import React from 'react';
import { FormattedMessage } from 'react-intl';

const MessageModal = props => {
    return (
        <div className={`modal fade show ${props.show ? 'modal-visible' : 'modal-hidden'}`} tabIndex="-1" role="dialog"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header MessageModalClose-btn">
                        <button onClick={props.withCancel? props.handleCancelAction : props.handleCloseModal} type="button" className="close" data-dismiss="modal"
                                aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body flex-center" style={{fontFamily:'Avenir Medium'}}>
                        {props.message}
                    </div>
                    <div className="modal-footer">
                        <button onClick={props.handleCloseModal} type="button" className="btn btn-primary w-100" style={{fontFamily:'Avenir Heavy'}}><FormattedMessage id='global.ok'/>
                        </button>
                        {
                            props.withCancel ?
                            <button onClick={props.handleCancelAction} type="button" style={{fontFamily:'Avenir Heavy'}}
                                        className="btn btn-danger w-100"><FormattedMessage id='global.cancel'/></button>
                                :
                                null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;

