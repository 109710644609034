import axios from "axios";
import history from "../history";
import {FETCH_RESTAURANTS, UPDATE_RESTAURANTS_PROP, RESET_RESTAURANTS} from "./types";
import {baseUrl, locale} from "../constantes/constantes";
import {hideConfirmationModal, showConfirmationModal} from "./confirmation";
import messages from "../locales/messages";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

export const fetchRestaurants = data => dispatch => {
    dispatch(updateProp({prop: 'loading', value: true}));
    let config = {
        method: 'get',
        url: `${baseUrl}/api/schedule`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${data.token}`},
    };
    axios(config)
        .then(res => {
                const user = JSON.parse(localStorage.getItem('user'));
                if (res.data.data && user && !user.corporate) {
                    const user = JSON.parse(localStorage.getItem('user'));
                    user.corporate = {name: res.data.data.name, logo: res.data.data.logo};
                    localStorage.setItem('user', JSON.stringify(user))
                }
                dispatch({
                    type: FETCH_RESTAURANTS,
                    payload: res.data.data ? res.data.data: null,
                })
            }
        )
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 404:
                    dispatch({
                        type: FETCH_RESTAURANTS,
                    });
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(() => {
            dispatch(updateProp({prop: 'loading', value: false}))
        });

};

export const resetRestaurants = () => dispatch => {
    dispatch({
        type: RESET_RESTAURANTS,
    })
};

export const updateProp = ({prop, value}) => {
    return {
        type: UPDATE_RESTAURANTS_PROP,
        payload: {prop, value}
    };
};

const showUnauthorizedModal = dispatch => {
    dispatch(updateProp({prop: 'loading', value: false}));
    dispatch(showConfirmationModal(
        msg['error.unauthorized'],
        () => {
            localStorage.clear();
            history.push('/login');
            dispatch(hideConfirmationModal());
        }, null));
};