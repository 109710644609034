import React from 'react';
import moment from 'moment';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { formatMoney } from '../../constantes/helpers'

import { baseUrl } from "../../constantes/constantes";
import { fetchOrderDetails, fetchOrderHelp } from "../../actions/orders";

const OrderItem = props => {
    const { merchant, created_at, original_amount,tax_amount } = props.order;
    return (
        <div className="col-md-4">
            <div className="order-item-container">
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <div style={{
                        height: '100px',
                        width: '40%',
                        backgroundImage: `url(${baseUrl + '/' + merchant.logo})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'contain',
                        backgroundPosition: "center center"
                    }} />
                </div>
                <div className="content-spaced-between">
                    <span className="order-text-blue">{merchant.name}</span>
                    <span style={{ color: "#7f7f7f" }}><FormattedMessage id="orders.you_paid" /></span>
                </div>
                <div className="content-spaced-between">
                    <span style={{color: "#7f7f7f"}}>{moment.unix(created_at).utc().format("MMMM D dddd")}</span>
                    <span className="order-text-blue">${props.order.status === 9 ?formatMoney(0):formatMoney((original_amount+tax_amount) / 100)}</span>
                </div>
                <div className="content-spaced-between">
                    <button onClick={() => props.fetchOrderDetails(props.order)} className="pink-button">
                        <FormattedMessage id="orders.receipt" />
                    </button>
                    <button onClick={() => props.fetchOrderHelp(props.order)} className="blue-button">
                        <FormattedMessage id="orders.get_help" />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default connect(null, { fetchOrderDetails, fetchOrderHelp })(OrderItem);