import React from 'react';
import axios from "axios";
import {FormattedMessage} from "react-intl";

import {baseApiUrl, locale} from "../../constantes/constantes";
import {checkValidationErrors} from "../../constantes/helpers";
import Spinner from '../common/Spinner';
import messages from '../../locales/messages';
import MessageModal from "../common/MessageModal";
import {connect} from "react-redux";
import {hideConfirmationModal, showConfirmationModal} from "../../actions/confirmation";
import history from "../../history";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

class ChangePassword extends React.Component {

    state = {
        current_password: '',
        new_password: '',
        password_confirmation: '',
        jwtToken: null,
        current_password_error: '',
        new_password_error: '',
        password_confirmation_error: '',
        loading: false,
        message: ''
    };

    componentWillMount() {
        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) this.setState({jwtToken: jwtToken});
    }

    onSubmitForm(e) {
        e.preventDefault();

        let {current_password, new_password, password_confirmation} = this.state;

        this.setState({current_password_error: '', new_password_error: '', password_confirmation_error: ''});

        let obj = {};
        current_password = current_password.trim();
        new_password = new_password.trim();
        password_confirmation = password_confirmation.trim();

        if (!current_password.length) {
            obj.current_password_error = msg['settings.required_password_field'];
        }

        if (!new_password.length) {
            obj.new_password_error = msg['settings.required__new_password_field'];
        }

        if (!password_confirmation.length) {
            obj.password_confirmation_error = msg['settings.required_password_confirmation_field'];
        }

        if ((new_password.length && password_confirmation.length) && (new_password !== password_confirmation)) {
            obj.password_confirmation_error = msg['settings.passwords_different'];
        }

        if (Object.keys(obj).length) {
            this.setState(obj);
        }

        if ((new_password.length && password_confirmation.length && current_password.length) && (new_password === password_confirmation)) {

            this.setState({
                current_password_error: '',
                new_password_error: '',
                password_confirmation_error: '',
                loading: true
            });

            let formData = new FormData();
            formData.append('current_password', current_password);
            formData.append('new_password', new_password);


            let config = {
                method: 'post',
                url: `${baseApiUrl}/password`,
                timeout: 20000,
                headers: {"Content-Type": "application/json", "Authorization": `Bearer ${this.state.jwtToken}`},
                data: formData
            };

            axios(config)
                .then(res => {
                    this.setState({
                        current_password: '',
                        new_password: '',
                        password_confirmation: '',
                        message: msg['settings.password_changed_success']
                    });
                })
                .catch(err => {
                    const {status} = err.response;
                    switch (status) {
                        case 401:
                            this.showUnauthorizedModal();
                            break;
                        case 422:
                            const {errors, message} = err.response.data;
                            if (errors) {
                                const object = checkValidationErrors(errors);
                                this.setState(object);
                            } else {
                                this.setState({current_password_error: message});
                            }
                            break;
                        default:
                            this.setState({message: msg['payment.add_payment_card_server_error']});
                    }
                })
                .finally(() => {
                    this.setState({loading: false});
                });
        }

    }

    showUnauthorizedModal = () => {
        this.setState({loading: false});
        this.props.showConfirmationModal(
            msg['error.unauthorized'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.hideConfirmationModal();
            }, null);
    };

    render() {
        return (
            <div className='container-fluid' style={{backgroundColor: "#f2f2f2", height: '100vh'}}>
                <form className="from-container" onSubmit={this.onSubmitForm.bind(this)}>
                    <div className="mb-4">
                        <h3 className="order-text-blue">
                            <FormattedMessage id="settings.change_password"/>
                        </h3>
                    </div>
                    <div className="form-group ChangePassword-form" >
                        <FormattedMessage id="settings.current_password">
                            {msg => (
                                <input type="password" value={this.state.current_password}
                                       onChange={e => this.setState({current_password: e.target.value})}
                                       className={`form-control ${this.state.current_password_error.length ? 'is-invalid' : ''}`}
                                       placeholder={msg}/>
                            )}
                        </FormattedMessage>
                        <div className="invalid-feedback">
                            {this.state.current_password_error}
                        </div>
                    </div>
                    <div className="form-group ChangePassword-form">
                        <FormattedMessage id="settings.new_password">
                            {msg => (
                                <input type="password" value={this.state.new_password}
                                       onChange={e => this.setState({new_password: e.target.value})}
                                       className={`form-control ${this.state.new_password_error.length ? 'is-invalid' : ''}`}
                                       placeholder={msg}/>
                            )}
                        </FormattedMessage>
                        <div className="invalid-feedback">
                            {this.state.new_password_error}
                        </div>
                    </div>
                    <div className="form-group ChangePassword-form">
                        <FormattedMessage id="settings.confirm_password">
                            {msg => (
                                <input type="password"
                                       className={`form-control ${this.state.password_confirmation_error.length ? 'is-invalid' : ''}`}
                                       value={this.state.password_confirmation}
                                       onChange={e => this.setState({password_confirmation: e.target.value})}
                                       placeholder={msg}/>
                            )}
                        </FormattedMessage>
                        <div className="invalid-feedback">
                            {this.state.password_confirmation_error}
                        </div>
                    </div>
                    <div className="flex-center">
                        <button className="pink-button">
                            <FormattedMessage id="global.save_changes"/>
                        </button>
                    </div>
                </form>

                <MessageModal show={this.state.message}
                              message={this.state.message}
                              handleCloseModal={() => this.setState({message: null})}/>


                <Spinner loading={this.state.loading}/>
            </div>
        );
    }
}

export default connect(null, {showConfirmationModal, hideConfirmationModal})(ChangePassword);