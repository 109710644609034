export const GET_ERRORS = 'GET_ERRORS';
export const CREATE_USER = 'CREATE_USER';
export const VALIDATE_USER = 'VALIDATE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const FETCH_ORDERS = 'FETCH_ORDERS';
export const FETCH_MORE_ORDERS = 'FETCH_MORE_ORDERS';
export const SHOW_ORDER_DETAILS = 'SHOW_ORDER_DETAILS';
export const HIDE_ORDER_DETAILS = 'HIDE_ORDER_DETAILS';
export const SHOW_HELP_MODAL = 'SHOW_HELP_MODAL';
export const HIDE_HELP_MODAL = 'HIDE_HELP_MODAL';
export const SEND_HELP_EMAIL_SUCCESS = 'SEND_HELP_EMAIL_SUCCESS';
export const SEND_HELP_EMAIL_ERROR = 'SEND_HELP_EMAIL_SUCCESS';
export const UPDATE_ORDERS_PROP = 'UPDATE_ORDERS_PROP';
export const FETCH_PROFILE = 'FETCH_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const FETCH_CITIES = 'FETCH_CITIES';
export const UPDATE_PROFILE_PROP = 'UPDATE_PROFILE_PROP';
export const RESET_PROFILE_FROM = 'RESET_PROFILE_FROM';
export const UPDATE_PROFILE_IMAGE = 'UPDATE_PROFILE_IMAGE';
export const FETCH_RESTAURANTS = 'FETCH_RESTAURANTS';
export const NO_RESTAURANTS = 'NO_RESTAURANTS';
export const FETCH_MERCHANT = 'FETCH_MERCHANT';
export const CHOOSE_SCHEDULE = "CHOOSE_SCHEDULE";
export const CONFIRM_ORDER = "CONFIRM_ORDER";
export const CANCEL_ORDER = "CANCEL_ORDER";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_ERROR = "ORDER_ERROR";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const FORGET_PASSWORD_ERROR = "FORGET_PASSWORD_ERROR";
export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_ERROR = "RESET_PASSWORD_ERROR";
export const SHOW_CONFIRMATION_MODAL = "SHOW_CONFIRMATION_MODAL";
export const HIDE_CONFIRMATION_MODAL = "HIDE_CONFIRMATION_MODAL";
export const RESET_RESTAURANTS = "RESET_RESTAURANTS";
export const UPDATE_RESTAURANTS_PROP = "UPDATE_RESTAURANTS_PROP";
export const UPDATE_AUTH_PROP = "UPDATE_AUTH_PROP";
export const UPDATE_MERCHANT_PROP = "UPDATE_MERCHANT_PROP";
export const UPDATE_ORDER_RESPONSE_PROP = "UPDATE_ORDER_RESPONSE_PROP";
export const UPDATE_FORGET_PASSWORD_PROP = "UPDATE_FORGET_PASSWORD_PROP";
export const UPDATE_SCHEDULE_STEP = "UPDATE_SCHEDULE_STEP";
export const START_UPDATE_ORDER = "START_UPDATE_ORDER";
export const END_UPDATE_ORDER = "END_UPDATE_ORDER";
export const CANCEL_EDIT= "CANCEL_EDIT"
