import React, { Component } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'

import './forgetPassword.css'
import Background from '../../assets/background.png'
import logo from '../../assets/logo.png'
import { resetPassword, updateProp } from '../../actions/forgetPassword'
import MessageModal from '../common/MessageModal'
import history from '../../history'
import Spinner from '../common/Spinner'

class ResetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            password: "",
            password_confirmation: "",
            errorPsw: "",
            errorPconfirmPsw: "",
        }
    }
    handleInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        })
    }
    handleCloseModal = () => {
        if (this.props.message === 'Your Password has been changed successfully') {
            history.push("/login")
        }
        this.props.updateProp({ prop: 'message', value: '' })
    }
    resetPassword = () => {
        this.setState({
            errorPsw: "",
            errorPconfirmPsw: ""
        })
        if (this.state.password === "") {
            this.setState({ errorPsw: !this.state.errorPsw })
        }
        else if (this.state.password_confirmation === "" || this.state.password_confirmation !== this.state.password) {
            this.setState({ errorPconfirmPsw: !this.state.errorPconfirmPsw })
        }
        else if (this.props.location.search) {
            let data = {
                token: this.props.location.search.split('=')[1],
                new_password: this.state.password,
                new_password_confirmation: this.state.password_confirmation
            }
            this.props.resetPassword(data)
        }

    }
    render() {
        const Style = {
            backgroundImage: `url(${Background})`,
            height: '100vh'
        };
        return (
            <div className="ForgetPasswordPage" style={Style}>
                <div className="ForgetPasswordPage-header">
                    <img src={logo} alt="" />
                </div>
                <div className="registerPage-text">
                    <p >ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="ForgetPasswordPage-text">
                    <FormattedMessage id='reset.Password.text' />
                </div>
                <div className="ForgetPasswordPageContainer">
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Password"
                            className={classnames('form-control form-control-lg', {
                                'is-invalid': this.state.errorPsw
                            })}
                            name="password"
                            onChange={this.handleInputChange}
                            value={this.state.password}
                            autocomplete="off"
                        />
                        {this.state.errorPsw && (<div className="registerError"><FormattedMessage id='error.complete.field'/></div>)}
                    </div>
                    <div className="form-group">
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            className={classnames('form-control form-control-lg', {
                                'is-invalid': this.state.errorPconfirmPsw
                            })}
                            name="password_confirmation"
                            onChange={this.handleInputChange}
                            value={this.state.password_confirmation}
                            autocomplete="off"
                        />
                        {this.state.errorPconfirmPsw && (<div className="registerError">Please complete this field and <br />verify that matches with the password</div>)}
                    </div>

                    <button onClick={this.resetPassword}> <FormattedMessage id='forget.Password' /></button>
                </div>
                <MessageModal show={this.props.message ? true : false}
                    message={this.props.message}
                    handleCloseModal={this.handleCloseModal}
                />
                <Spinner loading={this.props.loading} />
            </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        message: state.forgetPassword.message,
        loading: state.forgetPassword.loading
    };
};
export default connect(mapStateToProps, { resetPassword, updateProp })(ResetPassword);