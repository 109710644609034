import React, { Component } from 'react';
import { connect } from 'react-redux'
import axios from 'axios'
import classnames from 'classnames';

import { baseUrl } from "../../constantes/constantes";
import Background from '../../assets/background.png'
import { registerCreatedUser } from '../../actions/authentication'
import Spinner from '../../components/common/Spinner'
import { formatPhoneNumberUS2 } from '../../constantes/helpers'
import logo from '../../assets/logo.png'
import { FormattedMessage } from 'react-intl';
import history from '../../history'

class GoogleRegisterStep1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            invalidPhone: false,
            email: '',
            value: '',
            city_id_error: false,
            city_name: '',
            phone_number: '',
            country_code: "",
            gid: '',
            errors: {}
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    componentDidMount() {
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user)
        let token = localStorage.getItem('jwtToken');
        if (myuser && token) {
            if (myuser.first_name && myuser.last_name) {
                //test if user have a profile picture
                if (user.profile) {
                    this.props.history.push('/');
                } else {
                    history.push('/google-register4')
                }

            } else {
                history.push('/google-register3')
            }
        }
        axios.get(`${baseUrl}/api/city`)
            .then(res => this.setState({
                cities: res.data.data
            }))
            .catch(err => console.log(err))

    }
    handleSelectChange = (event) => {
        this.setState({ value: event.target.value });
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    googleRegister1 = () => {
        this.setState({
            invalidPhone: false,
            city_id_error: false,
            errors: {}
        })
        let googleResponse = JSON.parse(localStorage.getItem('googleResponse'))
        const user = {
            gid: googleResponse.profileObj.googleId,
            city_id: this.state.value,
            phone_number: this.state.phone_number.split('').filter(el => el !== '-').join('').slice(0, 10),
            email: googleResponse.profileObj.email,
            country_code: "US"
        }
        if (this.state.value === "") {
            this.setState({ city_id_error: true })
        }
        else if (this.state.phone_number.length < 10) {
            this.setState({ invalidPhone: true })
        }
        else (
            this.props.registerCreatedUser(user)

        )

    }
    prevStep = () => {
        history.push("/registerChoice")
    }
    render() {
        const { cities } = this.state
        const { errors } = this.state;
        const registerStyle = {
            backgroundImage: `url(${Background})`
        };
        return (
            <div className="registerPage" style={registerStyle}>
                <div className="registerPage-header">
                    <img src={logo} alt="" />
                </div>
                <div className="registerPage-text">
                    <p>ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="registerStep2">
                    <Spinner loading={this.props.auth.loading} />
                    <div className="form-group" style={{ position: "relative" }}>
                        <select
                            value={this.state.value}
                            className={classnames('form-control form-control-lg selectInput1')}
                            name="country_code"
                            onChange={this.handleSelectChange}
                        >
                            <option value="New Yorkt">(US) United States</option>
                        </select>
                    </div>
                    <div className="form-group" style={{ position: "relative" }}>
                        <select
                            value={this.state.value}
                            name="city_id"

                            className={classnames('form-control form-control-lg', {
                                'is-invalid': this.state.city_id_error
                            })}
                            onChange={this.handleSelectChange}
                        >
                            <option value="" selected disabled hidden>City</option>
                            {cities && cities.map((el) => <option value={el.id} key={el.id}>{el.name}</option>)}
                        </select>
                        {this.state.city_id_error && (<div className="registerError"><FormattedMessage id='error.complete.field'/></div>)}
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Phone Number"
                            className={classnames('form-control form-control-lg', {
                                'is-invalid': this.state.invalidPhone
                            })}
                            name="phone_number"
                            onChange={this.handleInputChange}
                            value={formatPhoneNumberUS2(this.state.phone_number)}
                            autocomplete="off"
                        />
                        {errors.phone_number && (<div className="registerError">{errors.phone_number[0]}</div>)}
                        {this.state.invalidPhone && (
                            <div className="registerError"><FormattedMessage id='register.valid.phone'/></div>)}
                        {errors.email && !errors.phone_number && (<div className="registerError" style={{ marginTop: '4%' }}><FormattedMessage id='google_email.error' /></div>)}

                    </div>
                    <div className="registerStep2-btn">
                         <button  onClick={this.prevStep}><FormattedMessage id='register.return'/></button>
                        <button onClick={this.googleRegister1}><FormattedMessage id='register.done'/></button>

                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,
});

export default connect(mapStateToProps, { registerCreatedUser })(GoogleRegisterStep1);