
import React, { Component } from "react";
import { MDBCollapse } from "mdbreact";

import ProductItem from './ProductItem'
import SubcategorieItem from './SubcategorieItem'
import { firstToUpperCase } from '../../constantes/helpers'

class ItemMenu extends Component {
  state = {
    collapseID: "",
    open: false,
    colorchange: false,
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({
      collapseID: prevState.collapseID !== collapseID ? collapseID : "",
      open: !this.state.open,
      colorchange: !this.state.colorchange
    }));
  }

  render() {
    const { item, handleChange } = this.props
    return (
      <div>
        <div className="merchantBody-collapse-btn" onClick={this.toggleCollapse(`${item.id}`)} >
          <div className={this.state.colorchange ? "merchantBody-collapse-category showed" : "merchantBody-collapse-category"} style={{ fontSize: "18px" }} >{firstToUpperCase(item.name)}</div>
          <i className={this.state.open ? "fa fa-angle-down rotate-icon" : "fa fa-angle-down "}></i>
        </div>
        <MDBCollapse className="merchantBody-collapse" id={`${item.id}`} isOpen={this.state.collapseID}>
          <div className="rowProduct">

            {item.products && item.products.map((el, index) => <div className="colProduct"><ProductItem  key={index} item={el} handleChange={handleChange}/></div>)}
          </div>
          {item.sub_categories && <div className="merchantBody-collapseSubcategory">
            {item.sub_categories && item.sub_categories.map(el => <div className="colProduct"> <SubcategorieItem id={`${item.id}`} key={el.id} item={el} handleChange={handleChange} style={{paddingLeft:'-5%'}}/></div>)}
          </div >}

        </MDBCollapse>
      </div>
    );
  }
}

export default ItemMenu;