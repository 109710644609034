import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCVCElement, injectStripe} from 'react-stripe-elements';
import history from "../history";
import messages from "../locales/messages";
import {locale} from "../constantes/constantes";
import {FormattedMessage} from "react-intl";
import {formatDate} from "../constantes/helpers";
import {connect} from "react-redux";
import {hideConfirmationModal, showConfirmationModal} from "../actions/confirmation";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

class SplitForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            cardNumber: '',
            cardExpiry: '',
            cardCvc: '',
            show_form: false
        };
    }

    handleBlur = () => {
        /* console.log('[blur]');*/
    };

    handleChange = (change) => {
        /* console.log('[change]', change);*/
        if (change.error) {
            this.setState({[change.elementType]: change.error.message});
        } else {
            this.setState({[change.elementType]: ''});
        }
    };

    handleFocus = () => {
        /*console.log('[focus]');*/
    };

    handleReady = () => {
        /*console.log('[ready]');*/
    };

    createOptions = (fontSize, padding) => {
        return {
            style: {
                base: {
                    ...fontSize,
                    color: '#424770',
                    letterSpacing: '0.025em',
                    fontFamily: 'Source Code Pro, monospace',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    ...padding,
                },
                invalid: {
                    color: '#9e2146',
                },
            },
        };
    };

    handleSubmit = (ev) => {
        ev.preventDefault();

        if (this.props.stripe) {
            this.props.toggleSpinner();
            this.props.stripe
                .createToken()
                .then((payload) => {
                    if (payload.token) {
                        this.props.addPaymentMethod(this.props.method, payload.token.id, (res, error) => {
                            if (res) {
                                const user = res.data.data;
                                localStorage.setItem('user', JSON.stringify(user));
                                this.props.showMessage(msg['payment.add_payment_card_success']);
                                this.setState({show_form: false});
                            } else {
                                const {status} = error.response;
                                switch (status) {
                                    case 401:
                                       this.showUnauthorizedModal();
                                        break;
                                    case 422:
                                        this.props.showMessage(msg['payment.add_payment_card_validation']);
                                        break;
                                    default:
                                        this.props.showMessage(msg['payment.add_payment_card_server_error']);
                                }
                            }
                        });
                    } else {
                        this.props.showMessage(payload.error.message);
                    }
                });
        } else {
            this.props.showMessage('Stripe.js hasn\'t loaded yet.');
        }
    };

    showUnauthorizedModal = () => {
        this.props.toggleSpinner();
        this.props.showConfirmationModal(
            msg['error.unauthorized'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.hideConfirmationModal();
            }, null);
    };

    render() {
        return (
            <div className="container" style={{padding: "4% 0% 4% 0%"}}>
                {
                    (this.props.card && !this.state.show_form) ?
                        <div className="flavour-form text-center">

                            <h5 className="cash-style mb-5">
                                {this.props.title}
                            </h5>

                            <div>
                                <ul className="list-unstyled">
                                    <li><FormattedMessage id='payment.card-numbers'/> : {this.props.card.last4}</li>
                                    <li><FormattedMessage id='payment.card-type'/> : {this.props.card.type}</li>
                                    <li><FormattedMessage id='payment.card-country'/> : {this.props.card.country}</li>
                                    <li><FormattedMessage id='payment.card-expiration'/> : {formatDate(this.props.card.expire_at)}</li>
                                </ul>
                            </div>

                            <button className="blue-button mt-5" onClick={() => this.setState({show_form: true})}>
                                <FormattedMessage id="payment.edit_card"/>
                            </button>
                        </div>
                        :
                        <div className="flavour-form text-center">

                            <h5 className="cash-style mb-5">
                                {this.props.title}
                            </h5>

                            <form onSubmit={this.handleSubmit}>
                                <div className="form-group row paymentForm">
                                    <label htmlFor="card_number" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="payment.card_number"/>
                                    </label>
                                    <div className="col-sm-10">
                                        <CardNumberElement
                                            className={`form-control ${this.state.cardNumber.length ? 'is-invalid' : ''}`}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            onReady={this.handleReady}
                                            {...this.createOptions(this.props.fontSize)}
                                        />
                                    </div>
                                    <div className="invalid-feedback">
                                        {this.state.cardNumberValue}
                                    </div>
                                </div>
                                <div className="form-group row paymentForm">
                                    <label htmlFor="expiry_date" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="payment.expiry_date"/>
                                    </label>
                                    <div className="col-sm-4">
                                        <CardExpiryElement
                                            className={`form-control ${this.state.cardExpiry.length ? 'is-invalid' : ''}`}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            onReady={this.handleReady}
                                            {...this.createOptions(this.props.fontSize)}
                                        />
                                    </div>

                                    <label htmlFor="cvc" className="col-sm-3 col-form-label" style={{textAlign:'center'}}>
                                        <FormattedMessage id="payment.cvc"/>
                                    </label>
                                    <div className="col-sm-3">
                                        <CardCVCElement
                                            className={`form-control ${this.state.cardCvc.length ? 'is-invalid' : ''}`}
                                            onBlur={this.handleBlur}
                                            onChange={this.handleChange}
                                            onFocus={this.handleFocus}
                                            onReady={this.handleReady}
                                            {...this.createOptions(this.props.fontSize)}
                                        />
                                    </div>
                                </div>

                                <div className="form-group row paymentForm">
                                    <label htmlFor="zip_code" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="payment.zip_code"/>
                                    </label>
                                    <div className="col-sm-10">
                                        <input className="form-control" id="zip_code"/>
                                    </div>
                                </div>

                                <div className="form-group row paymentForm">
                                    <label htmlFor="card_name" className="col-sm-2 col-form-label">
                                        <FormattedMessage id="payment.card_name"/>
                                    </label>
                                    <div className="col-sm-10">
                                        <input className="form-control" id="card_name"/>
                                    </div>
                                </div>

                                <button className="blue-button mt-5">
                                    <FormattedMessage id="payment.add_card"/>
                                </button>
                            </form>
                        </div>
                }
            </div>
        );
    }
}

export default connect(null, {showConfirmationModal, hideConfirmationModal})(injectStripe(SplitForm));