import React, { Component } from 'react';
import { MDBBtn } from 'mdbreact';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'

import Success from '../../assets/icon_successfully.png'
import { sendSnack, updateProp } from '../../actions/orderResponse'
import Spinner from '../../components/common/Spinner'
import MessageModal from '../common/MessageModal';
import history from '../../history'


class ConfirmationSuccess extends Component {
    constructor(props) {
        super(props);
        this.state = {
            merchantName: '',
            userName: '',
            value: '',
        }
    }

    TextAreaHandleChange = (event) => {
        this.setState({
            value: event.target.value,
        })

    }
    handleSubmit = (event) => {
        event.preventDefault();
        let token = localStorage.getItem('jwtToken')
        let data = new FormData()
        data.append('favourite_snack', this.state.value)
        if (this.state.value === "") {
            this.props.updateProp({ prop: 'message', value: <FormattedMessage id='home.favorite.snack.required' /> })
        }
        else {
            this.props.sendSnack(data, token)
        }

    }

    handleCloseModal = () => {
        if (this.state.value !== "") {
            history.push("/")
            localStorage.removeItem('scheduel')
            localStorage.removeItem('restauItemStorage')
            localStorage.removeItem('directProduct')
            localStorage.removeItem('finalOrder')
        }
        this.props.updateProp({ prop: 'message', value: '' })
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.message !== prevProps.message && this.props.message !== this.state.message) {
            this.setState({
                message: this.props.message
            })
        }
    }
    componentDidMount = () => {
        let merchant = JSON.parse(localStorage.getItem('restauItemStorage'))
        let user = JSON.parse(localStorage.getItem('user'))
        this.setState({
            merchantName: merchant ? merchant.name : '',
            userName: user ? user.first_name : ''
        }
        )
    }

    render() {
        let favourite_snack = JSON.parse(localStorage.getItem('user')).favorite_snack
      
        return (
            <div className="confirmationModal-container">
                <Spinner loading={this.props.orderResponse.loading} />
                <span onClick={() => history.push('/')} className="confirmationModal-button">x</span>
                <div className="confirmationModal-part1">
                    <div className="confirmationModal-photo-container" >
                         <img className="successImage" src={Success} alt=" "/> 
                    </div>
                    <div className="confirmationModal-message">
                        <div className="confirmationModal-message-success"><FormattedMessage id='home.confirmationModal.success' /></div>
                        <div className="confirmationModal-message-parts">
                            <FormattedMessage id='home.confirmationModal.partOne' />{this.state.merchantName}<FormattedMessage id='home.confirmationModal.partTow' />
                        </div>
                        <div className="confirmationModal-message-part4"><FormattedMessage id='home.confirmationModal.partThree' /></div>
                    </div>
                </div>
                {!favourite_snack &&
                    <div className="confirmationModal-part2">
                        <div className='confirmationModal-part2-text'>
                            <FormattedMessage id='home.confirmationModal.TimeCashOne' /> {this.state.userName} <FormattedMessage id='home.confirmationModal.TimeCashTow' />
                        </div>
                        <div className="confirmationModal-part2-form " >
                            <textarea
                                className="confirmationModal-part2-textArea"
                                value={this.state.value}
                                onChange={this.TextAreaHandleChange}
                                onKeyDown={this.TextAreaHandleChange}
                            />
                        </div>
                        <MDBBtn type="submit" className="confirmationModal-submit" color="secondary" onClick={this.handleSubmit} ><FormattedMessage id='global.submit' /></MDBBtn>
                    </div>}
                <MessageModal show={this.props.message ? true : false}
                    message={this.props.message}
                    handleCloseModal={this.handleCloseModal}
                />

            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        orderResponse: state.orderResponse,
        message: state.orderResponse.message
    };
};

export default connect(mapStateToProps, { sendSnack, updateProp })(ConfirmationSuccess);