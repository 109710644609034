import React, { Component } from 'react';
import { formatMoney } from '../../constantes/helpers'
import { firstToUpperCase } from '../../constantes/helpers'

class MerchantProductModifierItem extends Component {
    constructor(props) {

        super(props);
       

    }
    modifierHandle = (e) => {
        const { item, checked } = this.props
        let current_modifierClass_id = item.pivot.group_id
        let modifiersTab = JSON.parse(localStorage.getItem('modifiersTab'))
        let modifier = {
            name: item.name,
            price: item.pivot.price,
            id: item.id,
            force_tax: item.pivot.force_tax,
            chosen: current_modifierClass_id
        }
        if (checked == true) {
            document.getElementById(`radio_${item.id}`).checked=false
            this.setState({
                checked: false
            })
            let filteredTab = modifiersTab.filter(el => el.chosen != item.pivot.group_id)
            localStorage.setItem('modifiersTab', JSON.stringify(filteredTab))
        } else if (checked == false && modifiersTab == null) {
            modifiersTab = []
            modifiersTab.push(modifier)
            localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            this.setState({ checked: true });
            //  document.getElementById(`radio_${item.id}`).checked=true
        } else if (checked == false && modifiersTab != null) {
            let filteredTab = modifiersTab.filter(el => el.chosen != item.pivot.group_id)
            filteredTab.push(modifier)
            localStorage.setItem('modifiersTab', JSON.stringify(filteredTab))
            localStorage.setItem('modifierClass_id', item.pivot.group_id)
            this.setState({
                checked: true,
            });
            // document.getElementById(`radio_${item.id}`).checked=true
        }
    }
    componentDidMount() {
        const { item } = this.props
        let finalOrderTab = JSON.parse(localStorage.getItem('finalOrder'))
        let currentId = JSON.parse(localStorage.getItem('currentProductId'))
        let productIndex = localStorage.getItem('productIndex')
        if (finalOrderTab && productIndex) {
            let productId = finalOrderTab[productIndex].id
            let modifiersTab = finalOrderTab[productIndex].modifiers
            localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            if (modifiersTab && modifiersTab.filter(element => element.id == item.id).length) {
                if (productId == currentId) {
                    // this.setState({
                    //     checked:true
                    // })
                    document.getElementById(`radio_${item.id}`).checked = true
                }
            }
        }
    }

    render() {
        const { item, handleChange, checked } = this.props
        let current_modifierClass_id = item.pivot.group_id
        return (<div className="productItem custom-control custom-radio">
            <input
                type="radio"
                className="form-check-input custom-control-input"
                name={`modifier${current_modifierClass_id}`}
                value={`${item.id}`}
                onClick={this.modifierHandle}
                // onChange={handleChange}
                id={`radio_${item.id}`}
                // checked={checked}
            />
            <label className="form-check-label custom-control-label d-flex justify-content-between"
                for={`radio_${item.id}`}>
                <div className="productItem-title">{firstToUpperCase(item.name)}</div>
                <div className="productItem-price"> {`$ ${formatMoney(item.pivot.price / 100)}`}</div>
            </label>
        </div>);
    }
}

export default MerchantProductModifierItem;