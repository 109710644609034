import React, { Component } from 'react';
import classnames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux'

import './forgetPassword.css'
import { validateEmail } from '../../constantes/helpers'
import Background from '../../assets/background.png'
import logo from '../../assets/logo.png'
import { forgetPassword, updateProp } from '../../actions/forgetPassword'
import history from '../../history'
import MessageModal from '../common/MessageModal'
import Spinner from '../common/Spinner'
class ForgetPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errorMail: false,
            invalidMail: false,
            email: ""
        }
    }
    forgetPassword = () => {
        let data = {
            email: this.state.email,
            wa: 'test'
        }
        this.setState({
            errorMail: false,
            invalidMail: false,
        })
        console.log(this.state.errorMail)
        console.log("email " + this.state.email)
        if (this.state.email === "") {
            this.setState({ errorMail: !this.state.errorMail })
        }
        else if (!validateEmail(this.state.email)) {
            this.setState({ invalidMail: !this.state.invalidMail })
        }
        else {
            this.props.forgetPassword(data)
        }

    }
    handleCloseModal = () => {
        if (this.props.message === 'An email has been sent to yo to reset your password') {
            history.push("/login")
        }
        this.props.updateProp({ prop: 'message', value: '' })
    }
    handleInputChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    render() {
        const Style = {
            backgroundImage: `url(${Background})`,
            height: '100vh'
        };
        console.log(this.props.loading)
        return (
            <div className="ForgetPasswordPage" style={Style}>
                <div className="ForgetPasswordPage-header">
                    <img src={logo} alt="" />
                </div>
                <div className="registerPage-text">
                    <p >ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="ForgetPasswordPage-text">
                    <FormattedMessage id='forget.Password.text' />
                </div>
                <div className="ForgetPasswordPageContainer">
                    <div className="form-group">
                        <input
                            type="email"
                            placeholder="Email"
                            className={classnames('form-control form-control-lg', {
                                'is-invalid': this.state.invalidMail || this.state.errorMail
                            })}
                            name="email"
                            onChange={this.handleInputChange}
                            value={this.state.email}
                            autocomplete="off"
                        />
                        {this.state.errorMail ? <div className="registerError">  <FormattedMessage id='forget.passsword-email' /></div> : <div></div>}
                        {this.state.invalidMail ? <div className="registerError"><FormattedMessage id='forget.password-valid.mail' /></div> : <div></div>}
                    </div>
                    <button onClick={this.forgetPassword}> <FormattedMessage id='forget.Password' /></button>
                </div>
                <MessageModal show={this.props.message ? true : false}
                    message={this.props.message}
                    handleCloseModal={this.handleCloseModal}
                />
                <Spinner loading={this.props.loading} />
            </div>);
    }
}
const mapStateToProps = (state) => {
    return {
        message: state.forgetPassword.message,
        loading: state.forgetPassword.loading
    };
};

export default connect(mapStateToProps, { forgetPassword, updateProp })(ForgetPassword);