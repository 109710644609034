import React from 'react';
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";

import { hideOrderHelp, sendOrderHelp } from "../../actions/orders";
import messages from "../../locales/messages";
import { locale } from "../../constantes/constantes";

class HelpModal extends React.Component {

    state = {
        message: '',
        message_error: ''
    };

    handleSubmitForm = e => {
        e.preventDefault();
        const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

        this.setState({ message_error: '' });
        const order = this.props.order;
        const user = JSON.parse(localStorage.getItem('user'));
        let { message } = this.state;

        message = message.trim();

        if (!message.length) {
            this.setState({ message_error: msg['orders.required_message'] });
        } else {
            let formData = new FormData();
            formData.append('body', message);
            this.props.sendOrderHelp(user.token, order.id, formData);
            this.setState({ message: '' });
        }
    };

    closeModal = () => {
        this.setState({ message: '', message_error: '' });
        this.props.hideOrderHelp()
    };

    render() {
        const order = this.props.order;

        if (order) {
            return (
                <div className={`modal fade show ${this.props.showHelp ? 'modal-visible' : 'modal-hidden'}`}
                    tabIndex="-1"
                    role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title"style={{ fontFamily: 'Avenir Medium' }}><FormattedMessage id="orders.receipt_order_no" /> {order.id}
                                </h5>
                                <button onClick={this.closeModal} type="button" className="close"
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <form onSubmit={this.handleSubmitForm}>
                                    {/*<div className="form-group">
                                        <label htmlFor="subject">Subject</label>
                                        <input id="subject" className="form-control"/>
                                    </div>*/}
                                    <div className="form-group" style={{ fontFamily: 'Avenir Medium' }}>
                                        <label htmlFor="content"><FormattedMessage id='global.Message' /></label>
                                        <textarea id="content"
                                            style={{ fontFamily: 'Avenir Medium' }}
                                            value={this.state.message}
                                            onChange={e => this.setState({ message: e.target.value })}
                                            className={`form-control ${this.state.message_error.length ? 'is-invalid' : ''}`}
                                        />
                                        <div className="invalid-feedback">
                                            {this.state.message_error}
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        <button className="blue-button"><FormattedMessage id='global.Send' /></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return null;
    }

}

const mapStateToProps = state => {
    const { order, showHelp } = state.orders;
    return { order, showHelp };
};

export default connect(mapStateToProps, { hideOrderHelp, sendOrderHelp })(HelpModal);