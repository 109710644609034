import {CHOOSE_SCHEDULE} from '../actions/types'

let initialState = {
    merchant_id: "bla",
    meal: "",
    photo: ""
};

export default (state = initialState, action) => {
    switch (action.type) {
        case CHOOSE_SCHEDULE:
            return {
                ...state,
                merchant_id: action.payload.merchant_id,
                meal: action.payload.meal,
                photo: action.payload.photo,
                choice: action.payload.choice,
                meals: action.payload.meals
            };
        default:
            return state
    }
}

