import axios from 'axios';

export const checkValidationErrors = errors => {
    let object = {};

    Object.keys(errors).forEach(key => {
        object[key + '_error'] = errors[key][0]
    });

    return object;
};
/**base64 to image */
export const dataURLtoFile = (data_url, filename) => {
    var arr = data_url.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};
/**** Url to base64 */
export const UrlToCanvas = (imageUrl) => {
    /**myCanvas is the id of the canvas tag */
    var canvas = document.getElementById("myCanvas");
    var context = canvas.getContext("2d");
    var myImg = new Image();
    myImg.onload = function () {
        context.drawImage(myImg, 0, 0);
    };
    myImg.src = imageUrl;
    let dataURL = canvas.toDataURL();
    return dataURL
}

export const validateEmail = email => {
    const re = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const formatPhoneNumberUS = string => {
    let newStr = "";
    let str = string.split('-').join("");

    if (string.includes("+")) {
        str = str.substr(2);
    }

    if (str.length) {
        for (let i = 0; i < str.length; i++) {
            newStr += str[i];

            if ((newStr.length === 3 && str.length > 3) || (newStr.length === 7 && str.length > 6)) {
                newStr += "-";
            }
        }
    } else {
        return ""
    }

    return "+1-" + newStr;
};
export const formatPhoneNumberUS2 = string => {
    let newStr = "";
    let str = string.split('-').join("");

    if (string.includes("+")) {
        str = str.substr(2);
    }

    if (str.length) {
        for (let i = 0; i < str.length; i++) {
            newStr += str[i];

            if ((newStr.length === 3 && str.length > 3) || (newStr.length === 7 && str.length > 6)) {
                newStr += "-";
            }
        }
    } else {
        return ""
    }

    return newStr.slice(0, 12);
};

export const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
        let j = (i.length > 3) ? i.length % 3 : 0;

        return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    } catch (e) {
        console.log(e)
    }
};

export const firstToUpperCase = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatDate = timestamp => {
    let date = (new Date(timestamp * 1000));
    let month = date.getMonth() + 1;
    month = month.toString().length > 1 ? month : '0' + month;

    return month + '/' + date.getFullYear();
};

export const resetLocalStorage = () => {
    localStorage.removeItem('scheduel');
    localStorage.removeItem('subcategorieProduct');
    localStorage.removeItem('directProduct');
    localStorage.removeItem('modifiersTab');
    localStorage.removeItem('products');
    localStorage.removeItem('finalOrder');
    localStorage.removeItem('modifierClass_id')
    localStorage.removeItem('currentProductId')
};

export const sendAsyncRequest = (method, url, data, token, _callback, _unauthorized) => {
    let config = {
        method: method,
        url: url,
        timeout: 20000,
    };

    if (data) config.data = data;
    if (token) config.headers = { "Content-Type": "application/json", "Authorization": `Bearer ${token}` };

    axios(config).then((response) => {
        _callback(undefined, response);
    }).catch((error) => {
        if (_unauthorized) {
            switch (error.response.status) {
                case 401:
                    _unauthorized();
                    break;
                case 404:
                default:
                    _callback(error);
            }
        } else {
            _callback(error);
        }
    }).catch(() => {
        _callback(false);
    });
};