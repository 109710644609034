import { CONFIRM_ORDER, CANCEL_ORDER, UPDATE_ORDER_RESPONSE_PROP, ORDER_SUCCESS, ORDER_ERROR } from '../actions/types'
import React from 'react'
import { FormattedMessage } from 'react-intl';

let initialState = {
    message: '',
    loading: false,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case CONFIRM_ORDER:
            return { ...state, order: action.payload }
        case CANCEL_ORDER:
            return { ...state, order: action.payload }
        case ORDER_SUCCESS:
            return { ...state, message: <FormattedMessage id='Thank.you'/> }
        case ORDER_ERROR:
            return { ...state, message: action.payload }
        case UPDATE_ORDER_RESPONSE_PROP:
            return { ...state, [action.payload.prop]: action.payload.value };
        default:
            return state
    }
}