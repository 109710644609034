import React, {Component} from 'react';

import {baseUrl} from "../constantes/constantes";
import {connect} from "react-redux";

class Navbar extends Component {

    state = {
        corporate: null,
        profile: null,
        user_name: ''
    };

    componentWillMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (user) {
            this.setState({
                corporate: user.corporate,
                profile: user.profile,
                user_name: user.first_name + ' ' + user.last_name
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prev_user_name = this.state.user_name;
        const prev_profile = this.state.profile;
        if (prev_profile !== this.props.profile && this.props.profile.includes('http') && !this.props.profile.includes('localhost')) {
            this.setState({profile: this.props.profile})
        }
        if (this.props.message && (prev_user_name !== this.props.first_name + ' ' + this.props.last_name)) {
            this.setState({user_name: this.props.first_name + ' ' + this.props.last_name})
        }
    }

    render() {
        const {profile} = this.state;
        return (
            <nav className="nav-container">
                <div>
                    {this.state.corporate ?
                        <img alt="" style={{height: '50px', marginLeft: "33px"}} src={baseUrl + '/' + this.state.corporate.logo}/>
                        :
                        null
                    }
                </div>
                <div className="user-details">
                    <div className="mr-3" style={{backgroundImage: `url(${(profile && profile.includes('http')) ? this.state.profile : baseUrl + '/' + this.state.profile})`}}/>
                    <span className="mr-5 text-white" style={{fontFamily:'Avenir Medium'}}>{this.state.user_name}</span>
                </div>
            </nav>
        );
    }
}

const mapStateToProps = state => {
    const {profile, first_name, last_name, message} = state.profile;
    return {profile, first_name, last_name, message};
};

export default connect(mapStateToProps, {})(Navbar);