import React, { Component } from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import './App.css'
import store from './store';
import Login from './components/Signin';
import RestauList from './components/home/RestauList'
import RegisterChoice from './components/register/registerChoice';
import OrdersList from './components/orders/OrdersList';
import history from './history';
import Layout from './components/Layout';
import ChangePassword from './components/settings/ChangePassword';
import EditProfile from "./components/settings/EditProfile";
import FlavourProfile from './components/settings/FlavourProfile';
import TymeCash from "./components/cash/TymeCash";
import PaymentMethod from "./components/payment/PaymentMethod";
import ConfirmationPage from './components/home/ConfirmationPage'
import ConfirmationSuccess from './components/home/ConfirmationSuccess'
import ForgetPassword from './components/forgetPassword/ForgetPassword'
import ResetPassword from './components/forgetPassword/ResetPassword'
import GoogleRegisterStep1 from './components/googleRegister/GoogleregisterStep1'
import GoogleRegisterStep2 from './components/googleRegister/GoogleRegisterStep2'
import GoogleRegisterStep3 from './components/googleRegister/GoogleRegisterStep3'
import GoogleRegisterStep4 from './components/googleRegister/GoogleRegisterStep4'
import RegisterStep1 from './components/register/registerStep1'
import RegisterStep2 from './components/register/registerStep2'
import RegisterStep3 from './components/register/registerStep3'
import RegisterStep4 from './components/register/registerStep4'
import OrderRework from "./components/home/OrderRework";

class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    <Route exact path="/" component={() => <Layout route='/home'><RestauList /></Layout>} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/registerChoice" component={RegisterChoice} />
                    <Route path="/orders" component={() => <Layout route="/orders"><OrdersList /></Layout>} />
                    <Route path="/settings/password" component={() => <Layout route="/settings/password"><ChangePassword /></Layout>} />
                    <Route path="/settings/profile" component={() => <Layout route="/settings/profile"><EditProfile /></Layout>} />
                    <Route path="/settings/flavour" component={() => <Layout route="/settings/flavour"><FlavourProfile /></Layout>} />
                    <Route path="/cash" component={() => <Layout route="/cash"><TymeCash /></Layout>} />
                    <Route path="/payment" component={() => <Layout route="/payment"><PaymentMethod /></Layout>} />
                    <Route path="/confirmationPage" component={() => <Layout route="/home"><ConfirmationPage /></Layout>} />
                    <Route path="/confirmationSuccess" component={() => <Layout route="/home"><ConfirmationSuccess /></Layout>} />
                    <Route path="/forgetPassword" component={() => <ForgetPassword />} />
                    <Route path="/reset-password" component={(props) => <ResetPassword location={props.location} />} />
                    <Route path="/google-register1" component={() => <GoogleRegisterStep1 />} />
                    <Route path="/google-register2" component={() => <GoogleRegisterStep2 />} />
                    <Route path="/google-register3" component={() => <GoogleRegisterStep3 />} />
                    <Route path="/google-register4" component={() => <GoogleRegisterStep4 />} />
                    <Route path="/register1" component={() => <RegisterStep1 />} />
                    <Route path="/register2" component={() => <RegisterStep2 />} />
                    <Route path="/register3" component={() => <RegisterStep3 />} />
                    <Route path="/register4" component={() => <RegisterStep4 />} />
                    <Route exact path="/schedule" component={() => <Layout route="/home"><OrderRework /></Layout>} />
                </Router>
            </Provider>
        );
    }
}

export default App;
