import {SHOW_CONFIRMATION_MODAL, HIDE_CONFIRMATION_MODAL} from '../actions/types';

const INITIAL_STATE = {
    message: "",
    show: false,
    confirm: null,
    cancel: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_CONFIRMATION_MODAL:
            return {
                ...state,
                message: action.payload.message,
                show: true,
                confirm: action.payload.confirm,
                cancel: action.payload.cancel
            };
        case HIDE_CONFIRMATION_MODAL:
            return {
                ...state, message: '',
                show: false,
                confirm: null,
                cancel: null
            };
        default:
            return state;
    }
};