import axios from 'axios';
import { GET_ERRORS, SET_CURRENT_USER, CREATE_USER, VALIDATE_USER, UPDATE_USER, UPDATE_AUTH_PROP } from './types';
import setAuthToken from '../setAuthToken';
import jwt_decode from 'jwt-decode';
import { baseUrl } from '../constantes/constantes'
import history from '../history'

export const registerCreatedUser = (user) => dispatch => {
    dispatch(updateProp({ prop: 'loading', value: true }))
    let config = {
        method: 'post',
        url: `${baseUrl}/api/register`,
        timeout: 20000,
        headers: { "Content-Type": "application/json" },
        data: user
    }
    axios(config)
        .then(res => {
            dispatch({
                type: CREATE_USER,
                payload: res.data,
                statusRegitration: true
            })
            const user = res.data.data;
            localStorage.setItem('user', JSON.stringify(user))
            const googleResponse = JSON.parse(localStorage.getItem('googleResponse'))
            if (googleResponse) { history.push("/google-register2") }
            else { history.push("/register2") }
        }
        )
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data.errors : "",
                errorStatus: err.status
            });
            dispatch(updateProp({ prop: 'loading', value: false }))
        })
}
export const validateCreatedUser = (user) => dispatch => {
    dispatch(updateProp({ prop: 'loading', value: true }))
    let config = {
        method: 'post',
        url: `${baseUrl}/api/validate`,
        timeout: 20000,
        headers: { "Content-Type": "application/json" },
        data: user
    };
    axios(config)
        .then(res => {
            const user = res.data.data;
            const token = user.token;
            localStorage.setItem('jwtToken', token)
            localStorage.setItem('user', JSON.stringify(user))
            dispatch({
                type: VALIDATE_USER,
                payload: { status: true }
            })
            const googleResponse = JSON.parse(localStorage.getItem('googleResponse'))
            if (googleResponse) { history.push("/google-register3") }
            else { history.push("/register3") }
        }
        )
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data : "",
                errorStatus: err.status
            });
        })
        .finally(() => {
            dispatch(updateProp({ prop: 'loading', value: false }))
        })
}
export const updateCreatedUser = (user) => dispatch => {
    dispatch(updateProp({ prop: 'loading', value: true }))
    let config = {
        method: 'post',
        url: `${baseUrl}/api/profile`,
        timeout: 20000,
        data: user
    };
    let headers = { "Content-Type": "application/json", "Authorization": 'Bearer ' + user.token }
    config.headers = headers;
    axios(config)
        .then(res => {
            const user = res.data.data;
            const token = user.token;
            localStorage.setItem('jwtToken', token)
            localStorage.setItem('user', JSON.stringify(res.data.data));
            dispatch({
                type: UPDATE_USER,
                payload: { status: true }
            })
            const googleResponse = JSON.parse(localStorage.getItem('googleResponse'))
            if (googleResponse) { history.push("/google-register4") }
            else { history.push("register4") }
        }
        )
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response ? err.response.data.errors : "",
                errorStatus: err.status
            });
        })
        .finally(() => {
            dispatch(updateProp({ prop: 'loading', value: false }))
        }

        )
}

export const registerUser = (data) => dispatch => {
    dispatch(updateProp({ prop: 'loading', value: true }))
    let config = {
        method: 'post',
        url: `${baseUrl}/api/profile`,
        timeout: 20000
    };
    let formData = new FormData();
    formData.append("profile", data.profile)
    config.data = formData;
    let headers = { "Content-Type": "application/json", "Authorization": 'Bearer ' + data.token };
    config.headers = headers;
    axios(config)
        .then(res => {
            localStorage.setItem('user', JSON.stringify(res.data.data));
            history.push('/')
        })
        .catch(err => {
            console.log(err.response)

        })
        .finally(() => {
            dispatch(updateProp({ prop: 'loading', value: false }))
        }
        )
}
export const loginUser = (user) => dispatch => {
    dispatch(updateProp({ prop: 'loading', value: true }))
    dispatch({
        type: GET_ERRORS,
        payload: {}
    });
    axios.post(`${baseUrl}/api/login`, user)
        .then(res => {
            const user = res.data.data;
            const token = user.token;

            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('jwtToken', token);

            setAuthToken(token);
            const decoded = jwt_decode(token);

            dispatch(setCurrentUser(decoded));
            history.push('/')
        }

        )

        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err ? err.response.data : ""
            });
        })
        .finally((err) => {
            dispatch(updateProp({ prop: 'loading', value: false }))
        }

        )
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    }
}
export const updateProp = ({ prop, value }) => {
    return {
        type: UPDATE_AUTH_PROP,
        payload: { prop, value }
    }
};

