import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import { connect } from 'react-redux'
import { Container } from 'react-grid-system'
import { resetRestaurants } from '../../actions/restaurants'

import './home.css'
import RestauItem from './RestauItem';
import { fetchRestaurants } from '../../actions/restaurants'
import Spinner from '../common/Spinner'
import history from "../../history";
import { resetLocalStorage } from "../../constantes/helpers";
import { updateScheduleStep } from "../../actions/schedule";

class RestauList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            corporate: null,
        }
        this.props.resetRestaurants()
    }
    componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        const googleResponse = JSON.parse(localStorage.getItem('googleResponse'))
        if (user && user.token && user.first_name && user.last_name) {
            this.props.fetchRestaurants(user);
            this.setState({
                corporate: user.corporate
            })
        }
        else if (user && user.token && !user.first_name && !user.last_name && !googleResponse) {
            history.push('/register3')
        }
        else if (user && user.token && !user.first_name && !user.last_name && googleResponse) {
            history.push('/google-register3')
        }

        else {
            history.push('/login')
        }
        resetLocalStorage();
        localStorage.removeItem('restauItemStorage')
        this.props.updateScheduleStep(1)
    }

    renderList = () => {
        const { list } = this.props
        let user=JSON.parse(localStorage.getItem('user'))
        if ( !list || (list.length===0) || (list && list.schedules && list.schedules.length === 0)) {
            return (
                <div className='container-fluid' style={{
                    backgroundColor: "#f2f2f2", height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                
                }}>
                    <div style={{
                        position: "relative",
                        bottom: "5%",
                        fontFamily:'Avenir Medium'
                    }}>
                        {!this.props.loading ? <FormattedMessage id="home.not_found" /> : null}
                    </div>
                </div>
            );
        } else if(list && list.schedules && list.schedules.length) {
            return <div>
                <div className='HomeHeader'>
                   <FormattedMessage id='home.welcome'/>
                   <span> {user?user.first_name:''}! </span>
                   <FormattedMessage id="home.make_selection_text" />
                </div>
                <div className="HomeHeader-list-container">
                    {
                        list && list.schedules && list.schedules.map((el) => <RestauItem key={el.id} item={el} bonus={list.bonus} />)
                    }
                </div>

            </div>
        }
    }

    render() {
        const { loading } = this.props
        return (
            <Container fluid>
                <Spinner loading={loading} />
                <div>
                    {this.renderList()}
                </div>
            </Container>);
    }

}

const mapStateToProps = (state) => {
    const { list, loading, message } = state.restaurant;
    return { list, loading, message };
};

export default connect(mapStateToProps, { fetchRestaurants, resetRestaurants, updateScheduleStep })(RestauList);