import axios from "axios";

import {FETCH_MERCHANT, UPDATE_MERCHANT_PROP} from "./types";
import {baseUrl, locale} from "../constantes/constantes";
import history from "../history";
import {hideConfirmationModal, showConfirmationModal} from "./confirmation";
import messages from "../locales/messages";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

export const fetch_merchant = (data) => dispatch => {
    let config = {
        method: 'get',
        url: `${baseUrl}/api/merchant/${data.id}`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${data.token}`},
    };

    axios(config)
        .then(res => {
                dispatch({
                    type: FETCH_MERCHANT,
                    payload: res.data.data
                }, () => console.log(res.data.data));
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        )
        .catch((err) => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 404:
                default:
                    console.log(err)
            }
        });
};

export const updateProp = ({prop, value}) => {
    return {
        type: UPDATE_MERCHANT_PROP,
        payload: {prop, value}
    };
};

const showUnauthorizedModal = dispatch => {
    dispatch(updateProp({prop: 'loading', value: false}));
    dispatch(showConfirmationModal(
        msg['error.unauthorized'],
        () => {
            localStorage.clear();
            history.push('/login');
            dispatch(hideConfirmationModal());
        }, null));
};
