import { CREATE_USER, VALIDATE_USER, UPDATE_USER, SET_CURRENT_USER, UPDATE_AUTH_PROP, GET_ERRORS } from '../actions/types';
import isEmpty from '../validation/is-empty';

const initialState = {
    isAuthenticated: false,
    user: {},
    statusRegitration: false,
    statusValidation: false,
    statusUpdate: false,
    errorStatus: '',
    loading: false,
    errors: {},
}

export default (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USER:
            return {
                ...state,
                user: action.payload,
                loading: false,
            }
        case VALIDATE_USER:
            return {
                ...state,
                statusValidation: action.payload.status
            }
        case UPDATE_USER:
            return {
                ...state,
                statusUpdate: action.payload.status
            }
        case SET_CURRENT_USER:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.payload),
                user: action.payload
            }
        case UPDATE_AUTH_PROP:
            return {
                ...state,
                [action.payload.prop]: action.payload.value
            };
        case GET_ERRORS:
            return {
                ...state,
                errors: action.payload,
                errorStatus: action.errorStatus
            };
        default:
            return state;
    }
}