import React, {Component} from 'react';
import {connect} from 'react-redux'
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom'

import Background from '../../assets/background.png'
import {confirmOrder, cancelOrder} from '../../actions/orderResponse'
import history from '../../history'
import Spinner from '../../components/common/Spinner'

var counterIntervall;

class ConfirmationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            counter: 5,
        }
        let orderId = localStorage.getItem('orderId');
        if (!orderId) history.push('/');
    }

    componentDidMount = () => {
        if (this.state.counter > 0) {
            counterIntervall = setInterval(() => this.setState({counter: this.state.counter > 1 ? this.state.counter - 1 : 0})
                , 1000)
        } else {
            this.stopCounter()
        }
    }

    stopCounter = () => {
        clearInterval(counterIntervall);
        counterIntervall = false;
    }

    confirmOrder = () => {
        let token = localStorage.getItem('jwtToken')
        let orderId = localStorage.getItem('orderId')
        let data = {
            token: token,
            id: orderId
        }
        this.props.confirmOrder(data)
        this.stopCounter()
    }

    componentDidUpdate = () => {
        let token = localStorage.getItem('jwtToken')
        let orderId = localStorage.getItem('orderId')
        let data = {
            token: token,
            id: orderId
        }
        if (this.state.counter === 0 && counterIntervall) {
            this.stopCounter()
            this.props.confirmOrder(data)
        }
    }

    cancelOrder = () => {
        let token = localStorage.getItem('jwtToken')
        let orderId = localStorage.getItem('orderId')
        let data = {
            token: token,
            id: orderId
        }
        if (this.state.counter > 0) {
            this.props.cancelOrder(data)
        }
        this.stopCounter()
    }

    render() {
        const {counter} = this.state
        const ConfirmationPageStyle = {
            backgroundImage: `url(${Background})`,
        };
        return (
            <div className="confirmationPage-container" style={ConfirmationPageStyle}>
                <div className="confirmationPage">
                    <div className="confirmationPage-counter">
                        {counter}
                    </div>
                    <div className="confirmationPage-p">
                        <p>
                            <FormattedMessage id='home.confirmation.question'/>
                        </p>
                    </div>
                    <div className="confirmationPage-btn">
                        <Link to="/">
                            <button className="confirmationPage-btn-cancel" onClick={this.cancelOrder}>
                                < FormattedMessage id='home.ConfirmationPage.Cancel'/></button>
                        </Link>
                        <button className="confirmationPage-btn-confirm" onClick={this.confirmOrder}>< FormattedMessage
                            id='home.ConfirmationPage.Confirm'/></button>
                    </div>
                </div>
                <Spinner loading={this.props.orderResponse.loading}/>
            </div>);
    }
}

const mapStateToProps = (state) => ({
    orderResponse: state.orderResponse,

});

export default connect(mapStateToProps, {confirmOrder, cancelOrder})(ConfirmationPage);