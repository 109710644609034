import React from 'react';
import {connect} from "react-redux";

import SideBar from "./SideBar";
import Navbar from "./Navbar";
import MessageModal from "./common/MessageModal";

const Layout = props => {
    return (
        <div>
            <SideBar route={props.route}/>

            <div id="right-container">
                <Navbar/>
                <div className='content'>
                    {props.children}
                </div>
            </div>

            <MessageModal show={props.show} message={props.message}
                          handleCloseModal={props.confirm}
                          handleCancelAction={props.cancel} withCancel={props.cancel}/>
        </div>
    );
};

const mapStateToProps = (state) => {
    const {show, message, confirm, cancel} = state.confirm;
    return {show, message, confirm, cancel};
};

export default connect(mapStateToProps, {})(Layout);