import axios from "axios";
import moment from 'moment';

import {FETCH_ORDERS, SHOW_ORDER_DETAILS, HIDE_ORDER_DETAILS, FETCH_MORE_ORDERS, UPDATE_ORDERS_PROP, SHOW_HELP_MODAL, HIDE_HELP_MODAL, SEND_HELP_EMAIL_ERROR, SEND_HELP_EMAIL_SUCCESS} from "./types";
import {baseUrl, locale} from "../constantes/constantes";
import history from '../history';
import messages from "../locales/messages";
import {showConfirmationModal, hideConfirmationModal} from "./confirmation";
import {sendAsyncRequest} from "../constantes/helpers";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

export const fetchOrders = params => dispatch => {

    dispatch(updateOrdersProp({prop: 'loading', value: true}));

    sendAsyncRequest('GET', `${baseUrl}/api/order`, null, params.token,
        (err, res) => {
            if (res) {
                const {data, total, last_page} = res.data.data;

                let list = reformatOrderList(data);

                dispatch({
                    type: FETCH_ORDERS,
                    payload: {list, total, last_page}
                });

                dispatch(updateOrdersProp({prop: 'loading', value: false}));
            } else {
                if (err) {
                    dispatch(updateOrdersProp({prop: 'message', value: msg['payment.add_payment_card_server_error']}));
                } else {
                    console.log('connexion problem!');
                }
            }
        },
        () => {
            showUnauthorizedModal(dispatch);
        });

};

export const fetchOrderDetails = order => {
    return {
        type: SHOW_ORDER_DETAILS,
        payload: order
    };
};

export const hideOrderDetails = () => {
    return {
        type: HIDE_ORDER_DETAILS
    };
};

export const fetchOrderHelp = order => {
    return {
        type: SHOW_HELP_MODAL,
        payload: order
    };
};

export const hideOrderHelp = () => {
    return {
        type: HIDE_HELP_MODAL
    };
};

export const sendOrderHelp = (token, id, data) => dispatch => {

    dispatch(updateOrdersProp({prop: 'loading', value: true}));

    let config = {
        method: 'post',
        url: `${baseUrl}/api/order/${id}/support`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`},
        data: data
    };

    axios(config)
        .then(res => {
            dispatch({
                type: SEND_HELP_EMAIL_SUCCESS,
                payload: msg['orders.order_help_success_message']
            });
        })
        .catch(err => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 404:
                default:
                    dispatch({type: SEND_HELP_EMAIL_ERROR, payload: msg['payment.add_payment_card_server_error']});
            }
        });
};

export const fetchMoreOrders = (token, page = 1) => dispatch => {

    dispatch(updateOrdersProp({prop: 'loading', value: true}));

    let config = {
        method: 'get',
        url: `${baseUrl}/api/order?page=${page}`,
        timeout: 20000,
        headers: {"Content-Type": "application/json", "Authorization": `Bearer ${token}`},
    };

    axios(config)
        .then(res => {
            let {current_page, data, total, last_page} = res.data.data;

            let list = reformatOrderList(data);

            if (!data.length)
                current_page--;

            dispatch({
                type: FETCH_MORE_ORDERS,
                payload: {currentPage: current_page, list, total, last_page}
            });

            dispatch(updateOrdersProp({prop: 'loading', value: false}));
        })
        .catch(err => {
            const {status} = err.response;
            switch (status) {
                case 401:
                    showUnauthorizedModal(dispatch);
                    break;
                case 404:
                default:
                    dispatch(updateOrdersProp({prop: 'message', value: msg['payment.add_payment_card_server_error']}));
            }
        });
};

export const updateOrdersProp = ({prop, value}) => {
    return {
        type: UPDATE_ORDERS_PROP,
        payload: {prop, value}
    };
};

const reformatOrderList = orders => {
    let list = [];

    orders.forEach(order => {
        if (!list[moment.unix(order.created_at).utc().format("MMMM Y")]) {
            list[moment.unix(order.created_at).utc().format("MMMM Y")] = [];
        }

        list[moment.unix(order.created_at).utc().format("MMMM Y")].push(order);
    });

    return list;
};

const showUnauthorizedModal = dispatch => {
    dispatch(updateOrdersProp({prop: 'loading', value: false}));
    dispatch(showConfirmationModal(
        msg['error.unauthorized'],
        () => {
            localStorage.clear();
            history.push('/login');
            dispatch(hideConfirmationModal());
        }, null));
};