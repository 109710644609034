import React from 'react';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import Webcam from "react-webcam";

import {updateProfileProp, updateProfileImage} from "../../actions/profile";
import {dataURLtoFile} from "../../constantes/helpers";
import {videoConstraints} from "../../constantes/constantes";

class EditImageModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            choice: 0,
            allowCam: false
        };
    }

    componentDidMount() {
        navigator.mediaDevices
            .getUserMedia({video: true})
            .then((stream) => {
                    this.setState({allowCam: true});
                    stream.getTracks().forEach(function (track) {
                        track.stop();
                    });
                },
                () => {
                    this.setState({allowCam: false});
                });
    }

    setRef = webcam => {
        this.webcam = webcam;
    };

    capture = () => {
        try {
            const imageSrc = this.webcam.getScreenshot();
            const file = dataURLtoFile(imageSrc, "picture.jpg");
            this.props.updateProfileImage(imageSrc, file);
        } catch (e) {
            console.log('error cam');
        } finally {
            this.handleCloseModal();
        }
    };

    handleCloseModal = () => {
        this.setState({choice: 0});
        this.props.hide()
    };

    handleFileChange = e => {
        let file = e.target.files[0];
        if (file) {
            this.props.updateProfileImage(URL.createObjectURL(file), file);
            this.handleCloseModal();
        }
    };

    renderImageComponent() {
        switch (this.state.choice) {
            case 1:
                return (
                    <div>
                        <div className="flex-center">
                            <Webcam
                                audio={false}
                                height={280}
                                ref={this.setRef}
                                screenshotFormat="image/jpeg"
                                width={280}
                                videoConstraints={videoConstraints}
                            />
                        </div>
                        <div className="flex-center">
                            <button className="btn btn-primary" onClick={()=>{this.capture()}} style={{fontFamily:'Avenir Heavy'}}>
                                <FormattedMessage id="settings.take_your_picture"/>
                            </button>
                        </div>
                    </div>
                );
            case 2:
                return (
                    <div>
                        <input className="form-control" style={{display: "block",fontFamily:'Avenir Heavy'}} accept="image/*" type="file" onChange={this.handleFileChange} />
                    </div>
                );
            default:
                return (
                    <div className="flex-center">
                        <button disabled={(this.state.allowCam)? "" : "disabled"} className="btn btn-default" onClick={() => this.setState({choice: 1})} style={{fontFamily:'Avenir Heavy'}}>
                            <FormattedMessage id="settings.take_a_photo"/>
                        </button>
                        <button className="btn btn-default" onClick={() => this.setState({choice: 2})}style={{fontFamily:'Avenir Heavy'}}>
                            <FormattedMessage id="settings.file"/>
                        </button>
                    </div>
                );
        }
    }

    render() {
        return (
            <div className={`modal fade show ${this.props.show ? 'modal-visible' : 'modal-hidden' }`} tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{fontFamily:'Avenir Medium'}}>
                                <FormattedMessage id="settings.change_image"/>
                            </h5>
                            <button onClick={this.handleCloseModal} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            {this.renderImageComponent()}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => {
    const {profile, defaultState} = state.profile;
    return {profile, defaultState};
};

export default connect(mapStateToProps, {updateProfileProp, updateProfileImage})(EditImageModal);