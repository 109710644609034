import {
    FETCH_CITIES,
    FETCH_PROFILE,
    UPDATE_PROFILE,
    UPDATE_PROFILE_PROP,
    RESET_PROFILE_FROM,
    UPDATE_PROFILE_IMAGE
} from '../actions/types';

const INITIAL_STATE = {
    first_name: '',
    last_name: '',
    gender: 0,
    birth_date: null,
    email: '',
    phone_number: '',
    city_id: 0,
    cities: [],
    profile: '',
    defaultState: {},
    showEditImage: false,
    loading: false,
    picture_file: null,
    message: '',
    email_error: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case UPDATE_PROFILE:
        case FETCH_PROFILE:
            const {first_name, last_name, gender, email, phone_number, city_id, birth_date, profile, message} = action.payload;
            const defaultState = {first_name, last_name, gender, email, phone_number, city_id, birth_date, profile, };
            return {...state, first_name, last_name, gender, email, phone_number, city_id, birth_date, defaultState, profile, message, picture_file: null};
        case FETCH_CITIES:
            return {...state, cities: action.payload};
        case UPDATE_PROFILE_PROP:
            return {...state, [action.payload.prop]: action.payload.value};
        case RESET_PROFILE_FROM:
            return {...state, ...state.defaultState};
        case UPDATE_PROFILE_IMAGE:
            return {...state, profile: action.payload.profile, picture_file: action.payload.picture_file};
        default:
            return state;
    }
};