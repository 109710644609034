import React, { Component } from 'react';
import { formatMoney } from '../../constantes/helpers'
import { firstToUpperCase } from '../../constantes/helpers'

class MerchantProductModifierItemMultiple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: false,
        }
    }
    componentDidMount() {
        const { item } = this.props
        let finalOrderTab = JSON.parse(localStorage.getItem('finalOrder'))
        let currentId = JSON.parse(localStorage.getItem('currentProductId'))
        let productIndex = localStorage.getItem('productIndex')
        if (finalOrderTab && productIndex) {
            let productId = finalOrderTab[productIndex].id
            let modifiersTab = finalOrderTab[productIndex].modifiers
            localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            if (modifiersTab && modifiersTab.filter(element => element.id === item.id).length) {
                if (productId === currentId) {
                    this.setState({
                        checked: true
                    })
                }
            }
        }
    }
    handleChange = () => {
        this.setState({
            checked: !this.state.checked,
        }, () => {
            this.modifierHandle()
        }
        );
    }

    modifierHandle = () => {
        const { item } = this.props
        let current_modifierClass_id = item.pivot.group_id
        let modifiersTab = JSON.parse(localStorage.getItem('modifiersTab'))
        let modifier = {
            name: item.name,
            price: item.pivot.price,
            id: item.id,
            force_tax: item.pivot.force_tax,
            chosen:current_modifierClass_id
        }
        if (this.state.checked) {
            if (modifiersTab === null) {
                modifiersTab = []
                modifiersTab.push(modifier)
                localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            } else {
                modifiersTab.push(modifier)
                localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            }
        }
        else if (!this.state.checked) {
            if (modifiersTab === null) {
                modifiersTab = []
                localStorage.setItem('modifiersTab', JSON.stringify(modifiersTab))
            } else {
                let filteredTab = modifiersTab.filter(el => el.id !== item.id)
                localStorage.setItem('modifiersTab', JSON.stringify(filteredTab))
            }
        }
    }

    render() {
        const { item } = this.props
        return (<div className="productItem custom-control custom-checkbox">
            <input
                type="checkbox"
                name="modifier"
                value={`${item.id}`}
                onChange={this.handleChange}
                id={`check_${item.id}`}
                checked={this.state.checked}
                className="form-check-input custom-control-input"
            />
            <label className="form-check-label custom-control-label d-flex justify-content-between"
                for={`check_${item.id}`}>
                <div className="productItem-title">{firstToUpperCase(item.name)}</div>
                <div className="productItem-price"> {`$ ${formatMoney(item.pivot.price / 100)}`}</div>
            </label>
        </div>);
    }
}

export default MerchantProductModifierItemMultiple;