import React, {Component} from 'react';
import {MDBCollapse} from "mdbreact";
import {firstToUpperCase} from '../../constantes/helpers'
import SubcategorieItemProduct from './SubcategorieItemProduct'

class SubcategorieItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapseID: "",
            open: false,
            choice: "",
            colorchange: false
        }
    }

    toggleCollapse = collapseID => () => {
        this.setState(prevState => ({
            collapseID: prevState.collapseID !== collapseID ? collapseID : "",
            open: !this.state.open,
            colorchange: !this.state.colorchange
        }));
    }

    render() {
        const {item, handleChange} = this.props
        return (item.products.length > 0 ?
                <div className="subCategory-collapse">
                    <div className="merchantBody-collapse-btn" onClick={this.toggleCollapse(`${item.id}`)}>
                        <div
                            className="merchantBody-collapse-Subcategory" style={{color:'#fd4b95'}}>{firstToUpperCase(item.name)}</div>
                        <i className={this.state.open ? "fa fa-angle-down rotate-icon iconSubcategoryProduct" : "fa fa-angle-down iconSubcategoryProduct "}></i>
                    </div>
                    {item.products &&
                     <MDBCollapse className="merchantBody-collapse " id={`${item.id}`} isOpen={this.state.collapseID} >
                        <div className="merchantBody-collapseSubcategory">
                            <div  className="merchantBody-collapseSubcategory-items">
                            {item.products && item.products.map(el => <SubcategorieItemProduct key={el.id} item={el}  handleChange={handleChange}/>)}
                        </div>
                        </div>
                    </MDBCollapse>
                    }

                </div> :
                <div></div>

        );
    }
}

export default SubcategorieItem;