import React from 'react';
import {Link} from "react-router-dom";
import {baseUrl, locale} from "../../constantes/constantes";
import axios from "axios";
import {formatMoney} from "../../constantes/helpers";
import Spinner from "../common/Spinner";
import history from "../../history";

import cash from '../../assets/cash_icone.png';
import {FormattedMessage} from "react-intl";
import {connect} from "react-redux";
import {hideConfirmationModal, showConfirmationModal} from "../../actions/confirmation";
import messages from "../../locales/messages";

const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

class TymeCash extends React.Component {

    state = {
        jwtToken: null,
        loading: false,
        cash: ''
    };

    componentWillMount() {
        const user = localStorage.getItem('user');
        if (user) {
            const object = JSON.parse(user);
            this.setState({jwtToken: object.token});
        }
    }

    componentDidMount() {
        let config = {
            method: 'get',
            url: `${baseUrl}/api/profile`,
            timeout: 20000,
            headers: {"Content-Type": "application/json", "Authorization": `Bearer ${this.state.jwtToken}`},
        };

        this.setState({loading: true});

        axios(config)
            .then(res => {
                const data = res.data.data;
                this.setState({cash: data.tyme, loading: false})
            })
            .catch(err => {
                const {status} = err.response;
                switch (status) {
                    case 401:
                        this.showUnauthorizedModal();
                        break;
                    case 404:
                    default:
                        console.log(err)
                }
            });
    }

    showUnauthorizedModal = () => {
        this.setState({loading: false});
        this.props.showConfirmationModal(
            msg['error.unauthorized'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.hideConfirmationModal();
            }, null);
    };

    render() {
        return (
            <div className="container-fluid" style={{padding: "8% 0%"}}>
                <div className="flavour-form text-center">
                    <h5 className="cash-style">TymeCash</h5>

                    <img alt="" src={cash} className="mt-4"/>

                    <h4 className="cash-title mt-5" style={{fontFamily:'Avenir Heavy'}}>
                        <FormattedMessage id="cash.balance"/>
                    </h4>

                    <h1 className="cash-style mt-5">{typeof this.state.cash === "number" ? `$${formatMoney(this.state.cash / 100)}` : ''}</h1>

                    <p className="mt-5" style={{fontFamily:'Avenir Medium'}}>
                        <FormattedMessage id="cash.description"/>
                    </p>

                    <Link className="nav-link" to="/">
                        <button className="blue-button mt-5">
                            <FormattedMessage id="cash.order_now"/>
                        </button>
                    </Link>
                </div>

                <Spinner loading={this.state.loading}/>
            </div>
        );
    }
}

export default connect(null, {showConfirmationModal, hideConfirmationModal})(TymeCash);