import {UPDATE_SCHEDULE_STEP, START_UPDATE_ORDER, END_UPDATE_ORDER,CANCEL_EDIT} from "./types";

export const updateScheduleStep = step => {
    return {
        type: UPDATE_SCHEDULE_STEP,
        payload: step
    };
};

export const updateOrder = (step, index) => {
    return {
        type: START_UPDATE_ORDER,
        payload: {step, index}
    };
};

export const updateOrderDone = (step) => {
    return {
        type: END_UPDATE_ORDER,
        payload: {step}
    };
};
export const cancelEdit = () => {
    return {
        type: CANCEL_EDIT,
    };
};