import { createStore, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const inititalState = {};
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
        rootReducer,
        inititalState,
        composeEnhancers(
            applyMiddleware(thunk ),
        )
    );
// const store = createStore(
//         rootReducer, 
//         inititalState, 
//         compose(applyMiddleware(thunk), 
//                 window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__()));

export default store;
