import {UPDATE_SCHEDULE_STEP, START_UPDATE_ORDER, END_UPDATE_ORDER,CANCEL_EDIT} from '../actions/types'

let initialState = {
    step: 1,
    edit: false,
    index: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_SCHEDULE_STEP:
            return {...state, step: action.payload};
        case START_UPDATE_ORDER:
            return {...state, step: action.payload.step, edit: true, index: action.payload.index};
        case END_UPDATE_ORDER:
            return {...state, step: action.payload.step, edit: false, index: null};
        case CANCEL_EDIT:
            return{ ...state, edit:false}  
        default:
            return state
    }
};