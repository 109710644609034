import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import axios from 'axios'
import classnames from 'classnames';
import { baseUrl } from "../../constantes/constantes";
import Background from '../../assets/background.png'
import logo from '../../assets/logo.png'
import { registerCreatedUser} from '../../actions/authentication'
import { formatPhoneNumberUS2 } from '../../constantes/helpers'
import { validateEmail } from '../../constantes/helpers'
import Spinner from '../../components/common/Spinner'
import history from '../../history'
import { FormattedMessage } from 'react-intl';

class RegisterStep1 extends Component {

    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            password_confirmation: '',
            errorMail: false,
            invalidMail: false,
            errorPsw: false,
            errorPconfirmPsw: false,
            phone_number: '',
            invalidPhone: false,
            city_id: '',
            city_name: '',
            errors: {},
            step: 1
        }
    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSelectChange = (event) => {
        this.setState({ value: event.target.value });
    }
    nextStep = () => {
        this.setState({ step: this.state.step + 1 })
    }
    prevStep = () => {
        this.setState({ step: this.state.step - 1 })
        
        if(this.state.step===1){history.push("/registerChoice")}
    }
    verifStep1 = () => {
        this.setState({
            errorMail: false,
            invalidMail: false,
            errorPsw: false,
            errorPconfirmPsw: false
        })
        if (this.state.email === "") {
            this.setState({ errorMail: !this.state.errorMail })
        }
        else if (!validateEmail(this.state.email)) {
            this.setState({ invalidMail: !this.state.invalidMail })
        }
        else if (this.state.password === "") {
            this.setState({ errorPsw: !this.state.errorPsw })
        }
        else if (this.state.password_confirmation === "" || this.state.password_confirmation !== this.state.password) {
            this.setState({ errorPconfirmPsw: !this.state.errorPconfirmPsw })
        }
        else this.nextStep()
    }
    verifStep2 = () => {

        if (this.state.phone_number.length < 10) {
            this.setState({ invalidPhone: true })
        }
        else if (this.state.step === 2) {
            this.createUser()
        }
    }
    createUser = () => {
        const user = {
            email: this.state.email,
            password: this.state.password,
            password_confirmation: this.state.password_confirmation,
            city_id: this.state.value,
            phone_number: this.state.phone_number.split('').filter(el => el !== '-').join('').slice(0, 10)
        }
        this.props.registerCreatedUser(user, this.setState({ step: 2 }))
    }
    componentDidMount() {
        //test if user exist and have token
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user)
        let token = localStorage.getItem('jwtToken');
        if (myuser && token) {
            if (myuser.first_name && myuser.last_name) {
                //test if user have a profile picture
                if (user.profile) {
                    this.props.history.push('/');
                }
                else {
                    this.setState({ step: 5 })
                }

            }
            else if (!this.props.auth.statusValidation) {
                this.setState({
                    step: 4
                })
            }
        }
        axios.get(`${baseUrl}/api/city`)
            .then(res => this.setState({
                cities: res.data.data
            }))
            .catch(err => console.log(err))
        this.setState({
            focus: !this.state.focus
        })
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }

    renderStep1 = () => {
        const { errors } = this.state;
        return (<div className="registerstep1">
            <div className="form-group">
                <input
                    type="email"
                    placeholder="Email"
                    className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.email || this.state.invalidMail || this.state.errorMail
                    })}
                    name="email"
                    onChange={this.handleInputChange}
                    value={this.state.email}
                    autocomplete="off"
                />
                {this.state.invalidMail ? <div className="registerError"><FormattedMessage id='register.valid.mail'/></div> : <div></div>}
                {this.state.errorMail ? <div className="registerError"> <FormattedMessage id='error.complete.field'/></div> : <div></div>}
            </div>
            <div className="form-group">
                <input
                    type="password"
                    placeholder="Password"
                    className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.password || this.state.errorPsw
                    })}
                    name="password"
                    onChange={this.handleInputChange}
                    value={this.state.password}
                    autocomplete="off"
                />
                {errors.password && (<div className="registerError">{errors.password}</div>)}
                {this.state.errorPsw && (<div className="registerError"><FormattedMessage id='error.complete.field'/></div>)}
            </div>
            <div className="form-group">
                <input
                    type="password"
                    placeholder="Confirm Password"
                    className={classnames('form-control form-control-lg', {
                        'is-invalid': errors.password_confirm || this.state.errorPconfirmPsw
                    })}
                    name="password_confirmation"
                    onChange={this.handleInputChange}
                    value={this.state.password_confirmation}
                    autocomplete="off"
                />
                {errors.password_confirm && (<div className="registerError">{errors.password_confirm}</div>)}
                {this.state.errorPconfirmPsw && (<div className="registerError"><FormattedMessage id='error.complete.field'/><FormattedMessage id='register.and'/><br /><FormattedMessage id='register.password.confirm'/></div>)}
            </div>
            <div className="registerStep1-btn">
                <button onClick={this.prevStep}><FormattedMessage id='register.return'/></button>
                <button onClick={() => { this.verifStep1() }}><FormattedMessage id='register.done'/></button>
            </div>

        </div>)
    }
    renderStep2 = () => {

        const cities = this.state.cities
        const { errors } = this.state;
        return (
            <div className="registerStep2">
                <Spinner loading={this.props.auth.loading} />
                <div className="form-group" style={{ position: "relative" }}>
                    <select
                        value={this.state.value}
                        className={classnames('form-control form-control-lg selectInput1')}
                        name="city_id"
                        onChange={this.handleSelectChange}
                    >
                        <option value="New Yorkt">(US) United States</option>
                    </select>
                </div>
                <div className="form-group" style={{ position: "relative" }}>
                    <select
                        value={this.state.value}
                        name="city"

                        className={classnames('form-control form-control-lg', {
                            'is-invalid': errors.city_id
                        })}
                        onChange={this.handleSelectChange}
                    >
                        <option value="" selected disabled hidden>City</option>
                        {cities.map((el) => <option value={el.id} key={el.id}>{el.name}</option>)}
                    </select>
                    {errors.city_id && (<div className="registerError">{errors.city_id}</div>)}

                </div>
                <div className="form-group">
                    <input
                        type="text"
                        placeholder="Phone Number"
                        className={classnames('form-control form-control-lg', {
                            'is-invalid': errors.phone_number || this.state.invalidPhone
                        })}
                        name="phone_number"
                        onChange={this.handleInputChange}
                        value={formatPhoneNumberUS2(this.state.phone_number)}
                        autocomplete="off"
                    />
                    {errors.phone_number && (<div className="registerError">{errors.phone_number}</div>)}
                    {this.state.invalidPhone && (<div className="registerError"><FormattedMessage id='register.valid.phone'/></div>)}
                    {errors.email && (<div className="registerError" style={{ marginTop: '1%' }}>{errors.email}</div>)}
                </div>
                <div className="registerStep2-btn">
                    <button onClick={this.prevStep}><FormattedMessage id='register.return'/></button>
                    <button onClick={() => this.verifStep2()}><FormattedMessage id='register.done'/></button>

                </div>
            </div>
        )
    }
    renderSteps = (step) => {
        switch (step) {
            case 1: return this.renderStep1()
            case 2: return this.renderStep2()
            default: return this.renderStep1()
        }
    }
    render() {
        const registerStyle = {
            backgroundImage: `url(${Background})`
        };
        return (
            <div className="registerPage" style={registerStyle}>
                <div className="registerPage-header">
                    <img src={logo} alt="" />
                </div>
                <div className="registerPage-text">
                    <p>ENTERPRISE FOOD PROGRAM</p>
                </div>
                {this.renderSteps(this.state.step)}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,

});
export default connect(mapStateToProps, {registerCreatedUser})(withRouter(RegisterStep1))