import React, { Component } from 'react';
import { FormattedMessage } from "react-intl";
import moment from "moment"

import './home.css'
import DontForget from '../../assets/dont_forget_to_order.png'
import Order_placed from '../../assets/order_placed.png'
import { baseUrl } from "../../constantes/constantes";
import MessageModal from '../common/MessageModal'
import history from '../../history'

class RestauItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            showModal: false,
            choice: "",
            badgeType: false

        }
    }

    handleChange = (event) => {
        this.setState({
            choice: event.target.value
        });
    }

    handleRestauStorage = () => {
        const { item, bonus } = this.props
        let obj = {
            choice: this.state.choice,
            meals: item.meals,
            photo: item.merchant.photo1,
            name: item.merchant.name,
            merchant_id: item.merchant.id,
            bonus: bonus
        }
        localStorage.setItem('restauItemStorage', JSON.stringify(obj))

        this.setState({
            showModal: this.state.choice === ''
        }, () => {
            if ((!this.state.showModal && this.state.show) || item.meals.length === 1) {
                history.push(`/schedule`)
            } else history.push('/')
        })

    }

    compareDate = () => {
        const { item } = this.props
        var date = Date.now() / 1000;
        var bool = false;
        var start = item.start_at;
        var end = item.end_at;
        if (date <= end && date >= start) {
            bool = true;
        } else { bool = false }
        return bool
    }

    renderModal = () => {
        if (this.props.item.meals.length === 0) {
            return <MessageModal
                message={'You can choose a day before'}
                show={!!this.state.showModal}
                handleCloseModal={() => this.setState({ showModal: false })}
            />
        } else if (this.props.item.meals.length === 2) {
            return <MessageModal
                message={'Please choose a meal'}
                show={!!this.state.showModal}
                handleCloseModal={() => this.setState({ showModal: false })}
            />
        }
    }

    componentDidMount() {
        this.setState({
            show: this.compareDate(),
        })
        this.imageHandler()
    }

    imageHandler = () => {
        if (this.props.item.meals.length === 2) {
            this.setState({
                badgeType: (this.props.item.meals.map(el => el.orders_count !== 0)[0] === true && this.props.item.meals.map(el => el.orders_count !== 0)[1]) === true
            })
        } else if (this.props.item.meals.length === 1) {
            this.setState({
                badgeType: this.props.item.meals.map(el => el.orders_count !== 0)[0] === true
            })
        } else if (this.props.item.meals.length === 0) {
            this.setState({
                badgeType: false
            })
        }
    }
    renderRadioButtons = () => {
        const { item } = this.props
        if (item.meals.length === 2) {
            return <div
                className="form-check  form-radio-btn d-flex justify-content-around cardRestau-radio "
            >
                <div className="custom-control custom-radio">
                    <input
                        type="radio"
                        className="form-check-input custom-control-input"
                        name="meal"
                        value="Lunch"
                        id="lunch"
                        checked={this.state.choice === "Lunch"}
                        onChange={this.handleChange}

                    />
                    <label className="form-check-label custom-control-label" for="lunch"><FormattedMessage id="home.lunch" /></label>
                </div>
                <div className="custom-control custom-radio">
                    <input
                        type="radio"
                        className="form-check-input custom-control-input"
                        name="meal"
                        value="Dinner"
                        id="dinner"
                        checked={this.state.choice === "Dinner"}
                        onChange={this.handleChange}
                    />
                    <label className="form-check-label custom-control-label" for="dinner"><FormattedMessage id="home.dinner" /></label>
                </div>
            </div>

        }

    }
    render() {
        const { item } = this.props
        const cardstyle = {
            backgroundImage: `url(${item.merchant.photo1})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            opacity: 0.7,
            backgroundPosition: 'center center'
        }
        const rememberStyle = {
            backgroundImage: this.state.badgeType ? `url(${Order_placed})` : `url(${DontForget})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center center'
        }
        return (
            <div className="card cardRestau">
                <div className="cardDate">
                    <div>
                        {moment.parseZone(item.delivery_date).format("MMMM D_dddd").split("_")[1]}
                    </div>
                    <div>{moment.parseZone(item.delivery_date).format("MMMM D_dddd").split("_")[0]}</div>
                </div>
                <div className="photoMerchant" style={cardstyle}>
                </div>
                {this.state.show && <div className="remember" style={rememberStyle}></div>}
                <div className="logoMerchant-container"><img src={baseUrl + '/' + item.merchant.logo} className="logoMerchant" alt=""></img></div>

                <div className="card-body cardRestauBody d-flex flex-column">
                    <h5 className="card-title">{item.merchant.name}</h5>
                    {this.state.show ?
                        <p className="card-text">
                            <FormattedMessage id="home.able_to_choose" />
                            {this.renderRadioButtons()}
                        </p>
                        : <p className="card-text"><FormattedMessage id="home.not_able" /></p>
                    }

                    <div className="cardRestauLink-container">
                        <button
                            className="align-self-end btn  btn-block cardRestau-btn"
                            mt-auto
                            onClick={this.handleRestauStorage}
                        >
                            <FormattedMessage id='home.item_button' />
                        </button>
                        {this.renderModal()}

                    </div>
                </div>
            </div>
        )
    }

}

export default RestauItem