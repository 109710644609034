export default {
    en: {
        'global.save_changes': 'Save Changes',
        'global.cancel': 'Cancel',
        'global.first_name': 'First Name',
        'global.last_name': 'Last Name',
        'global.submit': 'Submit',
        'global.ok':'OK',
        'global.Message':'Message',
        'global.Send':'Send',
        'home.welcome': 'Welcome',
        'login.Forgot.your.password': 'Forgot your password?',
        'login.button': 'Sign In',
        'login.google.button': 'Sign with Google',
        'login.valid.email': 'Please enter a valid adress mail',
        'register.choice.google':'Sign up with Google',
        'register.choice.email':'Sign up with Email',
        'registerChoice.policy1':'By completing your registration, you accept our ',
        'registerChoice.policy2':'Privacy Policy',
        'register.and':'and',
        'registerChoice.policy4':'Terms and Condition',
        'register.done':'Done',
        'error.complete.field':'Please complete this field',
        'register.valid.mail':'Please enter a valid adress mail',
        'register.valid.phone':'Please add a valid phone number',
        'register.password.confirm':'verify that matches with the password',
        'register.return':'Return',
        'register.verify':'Verify',
        'register.verifcode1':'Enter your verification code',
        'register.verifcode2':'Check your message.',
        'register.verifcode3':'We have just sent you one',
        'register.lastname.empty':'Your last name is empty',
        'register.firstname.empty':'Your first name is empty',
        'register.take.profile.picture':'Take your profile picture',
        'register.take.picture':'Take your picture',
        'register.upload':'Upload from photos',
        'register.take.again':'Take again',
        'register.let.go':'Let\'s Go',
        'register.skip':'Skip',
        'complete.this.field': 'Please complete this field',
        'orders.you_paid': 'YOU PAID',
        'orders.receipt': 'Receipt',
        'orders.get_help': 'Get Help',
        'orders.not_found': 'No order Found',
        'orders.load_more': 'Load More',
        'orders.receipt_order_no': 'Receipt Order No.',
        'orders.merchant': 'Merchant',
        'orders.status': 'Status',
        'orders.order_details': 'Order Details',
        'orders.subtotal': 'Subtotal',
        'orders.checkout': 'Checkout',
        'orders.required_message': 'The message field is required.',
        'orders.order_help_success_message': 'Your message has been sent to our support team! We will be in touch with you within 24 hours.',
        'orders.add_another_order': 'Add Another Order',
        'orders.user_tyme_cash': 'Use TymeCash',
        'orders.additional_instructions': 'Additional Instructions',
        'orders.additional_instructions_content': 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer et arcu placerat, aliquet ipsum ut, elementum odio. Nam lacus dolor, suscipit et rutrum a, dictum vitae erat. Donec ullamcorper venenatis.',
        'orders.your_order': 'Your order',
        'orders.modifiers': 'Modifiers',
        'orders.discount': 'Discount / Offer For You',
        'cash.balance': 'Woohoo! Your TymeCash Balance is',
        'cash.description': 'Earn TymeCash on every order! Use earned TymeCash for more deliciousness at restaurants.',
        'cash.order_now': 'Order Now',
        'settings.change_password': 'Change Password',
        'settings.current_password': 'Current Password',
        'settings.new_password': 'New Password',
        'settings.confirm_password': 'Confirm Password',
        'settings.required_password_field': 'The current password field is required.',
        'settings.required__new_password_field': 'The new password field is required.',
        'settings.required_password_confirmation_field': 'The password confirmation field is required.',
        'settings.passwords_different': 'The new password and the password confirmation are different.',
        'settings.change_image': 'Change Image',
        'settings.edit_profile': 'Edit Profile',
        'settings.take_your_picture': 'TAKE YOUR PICTURE',
        'settings.take_a_photo': 'TAKE A PHOTO',
        'settings.file': 'FILE',
        'settings.required_first_name': 'The first name field is required.',
        'settings.required_last_name': 'The last name field is required.',
        'settings.required_email': 'The email field is required.',
        'settings.valid_email': 'The email must be a valid email address.',
        'settings.valid_phone_number': 'The phone number must be valid.',
        'settings.required_birth_date': 'The birth date field is required.',
        'settings.required_phone_number': 'The phone number field is required.',
        'settings.required_city': 'The city field is required.',
        'settings.flavour_title': 'Sharing your food preferences will help us provide better recommendations',
        'settings.password_changed_success': 'Your password has been changed successfully',
        'confirm.disconnect': 'Are you sure you want to disconnect ?',
        'confirm.delete_product': 'Product will be deleted from your order',
        'confirm.delete_order': 'Your order will be deleted',
        'payment.payment_title': 'Payment',
        'payment.payment_title1': 'Primary Card',
        'payment.payment_title2':'Secondary Card',
        'payment.card_number': 'Card Number',
        'payment.expiry_date': 'Expiry Date',
        'payment.cvc': 'CVC (3 or 4 digits)',
        'payment.zip_code': 'Zip/Postal Code',
        'payment.card_name': 'Card Name',
        'payment.add_card': 'Add Card',
        'payment.edit_card': 'Edit Card',
        'payment.payment_earning': 'You will earn $ % in TymeCash after placing this order',
        'payment.use_or_lose': '%amount is already covered by %corporate for this meal',
        'payment.not_enough_tyme_cash': 'You don\'t have enough TymeCash to complete this purchase. $%amount% in TymeCash will be applied and the remaining $%remaining% will be charged to your card on file',
        'payment.add_payment_card_success': 'Your card has been added successfully!',
        'payment.add_payment_card_validation': 'An error occurred while adding your card please check your data',
        'payment.add_payment_card_server_error': 'An error occurred. Please try later',
        'payment.card-numbers':'Last 4 numbers',
        'payment.card-type':'Type',
        'payment.card-country':'Country',
        'payment.card-expiration':'Expire at',
        'success.update_profile': 'Your profile is successfully updated',
        'home.make_selection_text': 'Please make all selections by 3pm the day before:',
        'home.item_button': 'View Entire Menu',
        'home.please_make_order': "Please place your order by 3pm so that it will be delivered on time by noon tomorrow",
        'home.not_able': ' You will be able to click in to make order selection a day before.',
        'home.able_to_choose': 'Please place your order by 3pm so that it will be delivered on time by noon tomorrow',
        'home.lunch': 'Lunch',
        'home.dinner': 'Dinner',
        'home.merchantHeader.time_delivery': 'Order will be delivered at',
        'home.merchantHeader.item_in': 'item(s) in',
        'home.merchantHeader.Food_Cart': 'your cart',
        'home.merchantBody.restaurant_menu': 'Restaurant Menu',
        'home.mechantBody.Add_to_Cart': 'Add to Cart',
        'home.not_found': 'Uh oh! It looks like you are not part of any corporate food programs yet. Have your company admin reach out to us for a trial',
        'home.MerchantProduct.add': 'Add to Order',
        'home.MerchantProduct.cancel': 'Cancel',
        'home.MerchantProduct.Modifiers': 'Modifiers',
        'home.ConfirmationPage.Cancel': 'Cancel',
        'home.ConfirmationPage.Confirm': 'Confirm',
        'home.confirmation.question': 'Changed your mind ? You have 5 seconds to cancel your order before it is sent over ' +
            'for preparation',
        'home.confirmationModal.success': "Order Successfully Submitted ",
        'home.confirmationModal.partOne': "Your order from ",
        'home.confirmationModal.partTow': " will be delivered at your company's lunch window {Estimated 11:45am - 12:15pm}. ",
        'home.confirmationModal.partThree': 'We look forward to making this a great lunch for you !',
        'home.confirmationModal.TimeCashOne': 'Hey ',
        'home.confirmationModal.TimeCashTow': 'want to earn $2 in TimeCash? Just share your favorite snack with us',
        'home.favorite.snack.required':'The favourite snack field is required',
        'forget.Password': 'Reset Password',
        'forget.Password.text': 'We will send you a reset email',
        'reset.Password.text': 'Reset Password',
        'Google.login.error': `We can't find a user with that account google`,
        'Email.login.error': 'Please verify your address mail and your password',
        'Google.register.step3': 'We will show your first name with your order',
        'google_email.error': 'Account is alerady created',
        'login.account.question': 'Don\'t have an account?',
        'error.unauthorized': 'Your session is expired.',
        'Thank.you':'Thank you',
        'sidebar.Home':'Home',
        'sidebar.TymeCash':'TymeCash',
        'sidebar.payment-method':'Payment Method',
        'sidebar.order-history':'Order History',
        'sidebar.settings':'Settings',
        'sidebar.edit-profile':'Edit Profile',
        'sidebar.change-password':'Change Password',
        'sidebar.flavor-profile':'Flavor Profile',
        'sidebar.logout':'Logout',
        'order.history-Successfully.Delivered':'Successfully Delivered',
        'order.history-Waiting.for.Merchant':'Waiting for Merchant',
        'order.history-date':'Date',
        'order.history-promo':'Promo',
        'order.history-tax':'Tax',
        'order.history-total':'Total',
        'order.history-card.payment':'Card Pyment',
        'order.history-tymecash.payment' :'TymeCash payment',
       'forget.passsword-email':' Please insert your adress mail',
        'forget.password-valid.mail':'Please enter a valid adress mail'
    },
    fr: {
        'home.welcome': 'Bienvenue'
    }
}