import React, {Component} from 'react';
import {connect} from 'react-redux'
import classnames from 'classnames';
import {FormattedMessage} from 'react-intl';
import Background from '../../assets/background.png'
import Spinner from '../common/Spinner'
import logo from '../../assets/logo.png'
import {updateCreatedUser} from '../../actions/authentication'
import history from '../../history'

class Register3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            shown: false,
            firstname: "",
            lastname: ""
        }
    }

    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    register = () => {
        const {firstname, lastname} = this.state
        if (firstname === "") {
            this.setState({show: !this.state.show})
        } else if (lastname === "") {
            this.setState({shown: !this.state.shown})
        } else {
            this.updateUser()
        }
    }
    updateUser = () => {
        let token = localStorage.getItem('jwtToken')
        const user = {
            token: token,
            first_name: this.state.firstname,
            last_name: this.state.lastname
        }
        this.props.updateCreatedUser(user)
    }
    componentDidMount() {
        //test if user exist and have token
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user)
        let token = localStorage.getItem('jwtToken');
        if (myuser && token) {
            if (myuser.first_name && myuser.last_name) {
                //test if user have a profile picture
                if (user.profile) {
                    this.props.history.push('/');
                } else {
                    history.push('/register4')
                }

            } else {
                history.push('/register3')
            }
        }
    }

    render() {
        const registerStyle = {
            backgroundImage: `url(${Background})`,

        };
        return (
            <div className="registerPage" style={registerStyle}>
                <div className="registerPage-header">
                    <img src={logo} alt=""/>
                </div>
                <div className="registerPage-text">
                    <p>ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="registerStep4">
                    <Spinner loading={this.props.auth.loading}/>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="First Name"
                            className={classnames('form-control form-control-lg', {'is-invalid': this.state.show})
                            }
                            name="firstname"
                            onChange={this.handleInputChange}
                            value={this.state.firstname}
                            autocomplete="off"
                        />
                        {this.state.show && (<div className="registerError"><FormattedMessage id='register.firstname.empty'/> </div>)}
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            placeholder="Last Name"
                            className={classnames('form-control form-control-lg', {'is-invalid': this.state.shown})}
                            name="lastname"
                            onChange={this.handleInputChange}
                            value={this.state.lastname}
                            autocomplete="off"
                        />
                        {this.state.shown && (<div className="registerError"><FormattedMessage id='register.lastname.empty'/></div>)}
                    </div>
                    <div className="registerStep4-btn">
                        <button onClick={() => this.register()}><FormattedMessage id='register.done'/></button>
                    </div>

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,

});
export default connect(mapStateToProps, {updateCreatedUser})(Register3);