import axios from "axios";
import {FORGET_PASSWORD, FORGET_PASSWORD_ERROR, RESET_PASSWORD, UPDATE_FORGET_PASSWORD_PROP} from './types'
import {baseUrl} from '../constantes/constantes'


export const forgetPassword = (data) => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/forgot-password`,
        timeout: 20000,
        headers: {"Content-Type": "application/json"},
        data: data
    };
    let headers = {"Content-Type": "application/json"};
    config.headers = headers;
    dispatch(updateProp({prop: 'loading', value: true}))
    axios(config)
        .then(res => {
            dispatch({
                type: FORGET_PASSWORD,
                payload: res.data
            })
            dispatch(updateProp({prop: 'loading', value: false}));
        })
        .catch(err => {
            dispatch({
                type: FORGET_PASSWORD_ERROR,
                payload: err.response.data.message
            });

        })
        .finally(
            res => {
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        );


}
export const resetPassword = (data, history) => dispatch => {
    let config = {
        method: 'post',
        url: `${baseUrl}/api/reset-password`,
        timeout: 20000,
    };
    let formData = new FormData();
    formData.append("token", data.token)
    formData.append("new-password", data.new_password)
    formData.append("new-password_confirmation", data.new_password_confirmation)
    config.data = formData;
    let headers = {"Content-Type": "application/json"};
    config.headers = headers;
    dispatch(updateProp({prop: 'loading', value: true}))
    axios(config)
        .then(res => {
                dispatch({
                    type: RESET_PASSWORD,
                    payload: res.data
                })
            }
        )
        .catch(err => {
            dispatch({
                type: FORGET_PASSWORD_ERROR,
                payload: err.response.data.message
            });
            switch (err.response) {
                case 401:
                    history.push('/login');
                    break;
                default:
                    console.log(err)
            }
        })
        .finally(
            res => {
                dispatch(updateProp({prop: 'loading', value: false}))
            }
        );
}
export const updateProp = ({prop, value}) => {
    return {
        type: UPDATE_FORGET_PASSWORD_PROP,
        payload: {prop, value}
    }
};