import React, {Component} from 'react';
import {connect} from 'react-redux'
import Background from '../../assets/background.png'
import Spinner from '../common/Spinner'
import logo from '../../assets/logo.png'
import {validateCreatedUser} from '../../actions/authentication'
import history from '../../history'
import { FormattedMessage } from 'react-intl';

class RegisterStep2 extends Component {
    constructor(props) {
        let registration_id = JSON.parse(localStorage.getItem('user')).id
        super(props);
        this.state = {
            registration_id: registration_id
        }
    }

    nextInput = (e) => {
        var target = e.srcElement || e.target;
        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
        var myLength = target.value.length;
        if (myLength >= maxLength) {
            var next = target;
            while (next = next.nextElementSibling) {
                if (next == null)
                    break;
                if (next.tagName.toLowerCase() === "input") {
                    next.focus();
                    break;
                }
            }
        }
        // Move to previous field if empty (user pressed backspace)
        else if (myLength === 0) {
            var previous = target;
            while (previous = previous.previousElementSibling) {
                if (previous == null)
                    break;
                if (previous.tagName.toLowerCase() === "input") {
                    previous.focus();
                    break;
                }
            }
        }

    }
    handleInputChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }
    handleSelectChange = (event) => {
        this.setState({value: event.target.value});
    }
    register = () => {
        let code = this.state.verif_code1 + this.state.verif_code2 + this.state.verif_code3 + this.state.verif_code4 + this.state.verif_code5 + this.state.verif_code6
        const user = {
            registration_id: this.state.registration_id,
            code: code
        }
        this.props.validateCreatedUser(user)
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    componentDidMount=()=>{
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user)
        let token = localStorage.getItem('jwtToken');
        if (myuser && token) {
            if (myuser.first_name && myuser.last_name) {
                //test if user have a profile picture
                if (user.profile) {
                    this.props.history.push('/');
                } else {
                    history.push('/register4')
                }

            } else {
                history.push('/register3')
            }
        }
    }

    render() {
        const {errors} = this.state;
        const registerStyle = {
            backgroundImage: `url(${Background})`,

        };
        return (
            <div className="registerPage" style={registerStyle}>
                <div className="registerPage-header">
                    <img src={logo} alt=""/>
                </div>
                <div className="registerPage-text">
                    <p>ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="registerstep3">
                    <Spinner loading={this.props.auth.loading}/>
                    <div className="registerStep3-text">
                        <p className="registerStep3-text-p1"><FormattedMessage id='register.verifcode1'/></p>
                        <p className="registerStep3-text-p2"><FormattedMessage id='register.verifcode2'/></p>
                        <p className="registerStep3-text-p3"><FormattedMessage id='register.verifcode3'/></p>
                    </div>
                    <div className="form-group registerStep3-input" onKeyUp={this.nextInput}>
                        <input
                            type="text"
                            className='form-control'
                            name="verif_code1"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code1 && this.state.verif_code1.slice(0, 1)}
                            maxlength="1"
                            autofocus="autofocus"
                        />
                        <input
                            type="text"
                            className='form-control '
                            name="verif_code2"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code2 && this.state.verif_code2.slice(0, 1)}
                            autocomplete="off"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            className='form-control '
                            name="verif_code3"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code3 && this.state.verif_code3.slice(0, 1)}
                            autocomplete="off"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            className='form-control '
                            name="verif_code4"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code4 && this.state.verif_code4.slice(0, 1)}
                            autocomplete="off"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            className='form-control'
                            name="verif_code5"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code5 && this.state.verif_code5.slice(0, 1)}
                            autocomplete="off"
                            maxlength="1"
                        />
                        <input
                            type="text"
                            className='form-control'
                            name="verif_code6"
                            onChange={this.handleInputChange}
                            value={this.state.verif_code6 && this.state.verif_code6.slice(0, 1)}
                            autocomplete="off"
                            maxlength="1"
                        />

                    </div>
                    {errors && errors.message && (<div className="registerError">{errors.message}</div>)}
                    <div className="registerStep3-btn">
                        <button onClick={() => this.register()}><FormattedMessage id='register.verify'/></button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,

});
export default connect(mapStateToProps, {validateCreatedUser})(RegisterStep2);