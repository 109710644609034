import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Container, Row, Col } from 'react-grid-system'

import MerchantHeader from './MerchantHeader'
import { baseUrl } from '../../constantes/constantes'
import Spinner from '../common/Spinner'
import MerchantProductModifier from './MerchantProductModifier'
import { connect } from "react-redux";
import { updateScheduleStep, updateOrderDone } from "../../actions/schedule";
import { firstToUpperCase } from '../../constantes/helpers'

class MerchantProductRework extends Component {

    constructor(props) {
        super(props);
        this.state = {
            quantity: 1
        }
    }

    AddOrder = () => {
        const { quantity } = this.state;
        let directProduct = JSON.parse(localStorage.getItem('directProduct'));
        let modifiersTab = JSON.parse(localStorage.getItem('modifiersTab'));
        let final = JSON.parse(localStorage.getItem('finalOrder'));
        let schedule = JSON.parse(localStorage.getItem('scheduel'));
        let stateRadioButtons = JSON.parse(localStorage.getItem('stateRadioTab'));
        let obj = {};
        const { edit, index } = this.props;
        if (!final) {
            final = [];
            obj = {
                ...directProduct,
                quantity,
                modifiers: modifiersTab,
                tax: schedule.merchant.merchant.tax,
                stateRadioButtons: stateRadioButtons
            };
            final.push(obj);
        } else {
            obj = final.length ? final[index] : null;
            if (obj && edit) {
                final[index] = { ...obj, quantity, modifiers: modifiersTab };
            } else {
                obj = {
                    ...directProduct,
                    quantity,
                    modifiers: modifiersTab,
                    tax: schedule.merchant.merchant.tax,
                    stateRadioButtons: stateRadioButtons
                };
                final.push(obj);
            }
        }
        localStorage.setItem('finalOrder', JSON.stringify(final));

        if (edit) {
            this.props.updateOrderDone(3)
        } else {
            this.props.updateScheduleStep(3);
        }
    };

    cartHandlerCancel = () => {
        localStorage.removeItem('directProduct');
        localStorage.removeItem('modifiersTab');
        localStorage.removeItem('products');
        this.props.updateOrderDone(1);
    };

    addQuantity = () => {
        this.setState({
            quantity: this.state.quantity + 1
        })
    };

    decreaseQuantity = (quantity) => {
        if (quantity >= 2) {
            return this.setState({
                quantity: this.state.quantity - 1
            })
        }
    };

    componentDidMount() {
        let final = JSON.parse(localStorage.getItem('finalOrder'));
        const { edit, index } = this.props;
        if (final && edit) {
            const obj = final.length ? final[index] : null;
            if (obj) this.setState({ quantity: obj.quantity });
        }
        localStorage.removeItem('products')

    }

    render() {
        let scheduel = JSON.parse(localStorage.getItem('scheduel'));
        const { merchant, photo, loading, meal, delivery_time } = scheduel;
        let directProduct = JSON.parse(localStorage.getItem('directProduct'));
        let final = JSON.parse(localStorage.getItem('finalOrder'));
        const { edit, index } = this.props;
        if (edit && final && final.length) {
            directProduct = final[index];
            localStorage.setItem('currentProductId', JSON.stringify(directProduct.id))
        }
        return (
            <Container className="merchantProductPage">
                <MerchantHeader
                    name={merchant !== undefined ? merchant.merchant.name : ''}
                    description={merchant !== undefined ? merchant.merchant.description : ''}
                    logo={merchant !== undefined ? baseUrl + '/' + merchant.merchant.logo : ''}
                    delivery_time={delivery_time}
                    meal={meal}
                    photo={photo}
                    cartProducts={this.state.cartProducts}
                    cartProductsColor={this.state.cartProductsColor}
                />
                < Spinner loading={loading} />
                <div className="merchantProductBody">
                    <div className="merchantProductBody-container">
                        <div className="merchantProductBody-title-container">
                            <Row className="merchantProductBody-title">
                                <Col lg={10}>
                                    {directProduct && firstToUpperCase(directProduct.title)}
                                </Col>
                                <Col lg={2}
                                    className="merchantProduct-quantity-container d-flex justify-content-around">
                                    <i class="fas fa-minus-circle" style={{ color: '#01aff5' }} onClick={(quantity) => this.decreaseQuantity(this.state.quantity)}></i>
                                    <div className="merchantProduct-quantity">{this.state.quantity}</div>
                                    <i class="fas fa-plus-circle" style={{ color: '#01aff5' }} onClick={this.addQuantity}></i>
                                </Col>
                            </Row>
                            <Row className="merchantProductBody-description">
                                <Col>
                                    {directProduct && directProduct.description}
                                </Col>
                            </Row>
                        </div>
                        <div className="merchantProductBody-container">
                            {directProduct && directProduct.groups.length ?
                                <Row className="merchantProductBody-modifiers"> <FormattedMessage
                                    id='home.MerchantProduct.Modifiers' /></Row> : null}

                        </div>
                        <div>{directProduct && directProduct.groups.map((el, index) => <MerchantProductModifier
                            key={index} item={el} />)}</div>

                        <Row className="merchantProduct-btn">
                            <Col>
                                <button onClick={this.AddOrder}>
                                    <FormattedMessage id="home.MerchantProduct.add" />
                                </button>
                            </Col>
                            <Col>
                                <button className="merchantProduct-btn-cancel" onClick={this.cartHandlerCancel}>
                                    <FormattedMessage id="home.MerchantProduct.cancel" />
                                </button>
                            </Col>
                        </Row>
                    </div>
                </div>

            </Container>);
    }
}
const mapStateToProps = state => {
    const { edit, index } = state.schedule;
    return { edit, index };
};

export default connect(mapStateToProps, { updateScheduleStep, updateOrderDone })(MerchantProductRework);