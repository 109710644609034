import React from 'react';
import { StripeProvider, Elements } from 'react-stripe-elements';
import SplitForm from "../SplitForm";

import { baseUrl, stripeApiKey } from "../../constantes/constantes";
import axios from "axios";
import Spinner from "../common/Spinner";
import MessageModal from "../common/MessageModal";
import { FormattedMessage } from 'react-intl';

class PaymentMethod extends React.Component {

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user'));
        this.state = {
            user,
            loading: false,
            message: ''
        };
    }

    toggleSpinner = () => {
        this.setState({ loading: !this.state.loading });
    };

    showMessage = message => {
        let user = JSON.parse(localStorage.getItem('user'));
        this.setState({ message: message, loading: false, user });
    };

    addPaymentMethod = (method, token, _callback) => {

        let formData = new FormData();
        formData.append('payment_method_number', method);
        formData.append('provider', 'stripe');
        formData.append('provider_data', token);

        let config = {
            method: 'post',
            url: `${baseUrl}/api/payment-method`,
            timeout: 20000,
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${this.state.user ? this.state.user.token : null}`
            },
            data: formData
        };

        axios(config)
            .then(res => {
                _callback(res, undefined);
            })
            .catch(err => {
                _callback(undefined, err);
            });

    };
    renderTitle1 = () => {
        return <FormattedMessage id="payment.payment_title1" />
    }
    renderTitle2 = () => {
        return <FormattedMessage id="payment.payment_title2" />
    }
    render() {
        return (
            <StripeProvider apiKey={stripeApiKey} locale="en">
                <div>
                    <Elements>
                        <SplitForm fontSize={{ fontSize: "18px" }} method="1"
                            card={this.state.user ? this.state.user.payment1_provider_data : null}
                            showMessage={this.showMessage}
                            toggleSpinner={this.toggleSpinner}
                            addPaymentMethod={this.addPaymentMethod}
                            title={this.renderTitle1()} />
                    </Elements>
                    <Elements>
                        <SplitForm fontSize={{ fontSize: "18px" }} method="2"
                            card={this.state.user ? this.state.user.payment2_provider_data : null}
                            showMessage={this.showMessage}
                            toggleSpinner={this.toggleSpinner}
                            addPaymentMethod={this.addPaymentMethod}
                            title={this.renderTitle2()} />
                    </Elements>

                    <Spinner loading={this.state.loading} />

                    <MessageModal show={this.state.message}
                        message={this.state.message}
                        handleCloseModal={() => this.setState({ message: null })} />

                </div>
            </StripeProvider>
        );
    }
}

export default PaymentMethod;