import React from 'react';
import {connect} from "react-redux";

import {fetchOrders, fetchMoreOrders, updateOrdersProp} from "../../actions/orders";
import OrderItem from './OrderItem';
import DetailsModal from './DetailsModal';
import HelpModal from './HelpModal';
import Spinner from '../common/Spinner';
import {FormattedMessage} from "react-intl";
import MessageModal from "../common/MessageModal";

class OrdersList extends React.Component {

    state = {jwtToken: null};

    componentWillMount() {
        const jwtToken = localStorage.getItem('jwtToken');
        if (jwtToken) this.setState({jwtToken: jwtToken});
    }

    componentDidMount() {
        const params = {
            token: this.state.jwtToken
        };

        this.props.fetchOrders(params);
    }

    renderOrders(orders) {
        return orders.map(order => <OrderItem order={order} key={order.id}/>);
    }

    renderList() {
        return Object.keys(this.props.list).map(key => {
            return (
                <div key={key}>
                    <div style={{paddingTop: '30px', color: '#353535', fontWeight: 'bold'}}>{key}</div>
                    <div className="row">
                        {this.renderOrders(this.props.list[key])}
                    </div>
                </div>
            );
        })
    }

    renderComponent() {
        if (!this.props.loading && !Object.keys(this.props.list).length) {
            return (
                <div className='container-fluid' style={{
                    backgroundColor: "#f2f2f2", height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    fontFamily:'Avenir Medium'
                }}>
                    <FormattedMessage id="orders.not_found"/>
                </div>
            );
        }

        return (
            <div className='container-fluid' style={{backgroundColor: "#f2f2f2"}}>
                {this.renderList()}
                <DetailsModal/>
                <HelpModal/>
                {this.renderMoreOrdersButton()}

                <MessageModal show={this.props.message}
                              message={this.props.message}
                              handleCloseModal={() => this.props.updateOrdersProp({prop: 'message', value: ''})}/>

                <Spinner loading={this.props.loading}/>
            </div>
        );
    }

    renderMoreOrdersButton() {
        if (this.props.total > 10 && (this.props.last_page > this.props.currentPage)) {
            return (
                <div className="flex-center" style={{marginTop: "50px"}}>
                    <button className="btn btn-warning" onClick={this.fetchMoreOrders} style={{fontFamily:'Avenir Heavy'}}>
                        <FormattedMessage id="orders.load_more"/>
                    </button>
                </div>
            );
        }
    }

    fetchMoreOrders = () => {
        const token = this.state.jwtToken;
        const page = this.props.currentPage + 1;

        this.props.fetchMoreOrders(token, page);
    };

    render() {
        return <div>{this.renderComponent()}</div>;
    }
}

const mapStateToProps = (state) => {
    const {currentPage, loading, list, total, message, last_page} = state.orders;
    return {currentPage, loading, list, total, message, last_page};
};

export default connect(mapStateToProps, {fetchOrders, fetchMoreOrders, updateOrdersProp})(OrdersList);