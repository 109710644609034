import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR, RESET_PASSWORD, UPDATE_FORGET_PASSWORD_PROP, RESET_PASSWORD_ERROR } from '../actions/types'


let initialState = {
    loading: false,
    message: '',
}

export default (state = initialState, action) => {
    switch (action.type) {
        case FORGET_PASSWORD:
            return { ...state, response: action.payload, message:'An email has been sent to yo to reset your password' }
        case FORGET_PASSWORD_ERROR:
            return { ...state, message: action.payload }
        case RESET_PASSWORD:
            return { ...state, response: action.payload,message:'Your Password has been changed successfully' }
        case RESET_PASSWORD_ERROR:
            return { ...state, message: action.payload }
        case UPDATE_FORGET_PASSWORD_PROP:
            return { ...state, [action.payload.prop]: action.payload.value };
        default:
            return state
    }
}