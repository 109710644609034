import React, { Component } from 'react';
import history from '../../history';
import { formatMoney, resetLocalStorage, firstToUpperCase } from "../../constantes/helpers";
import { FormattedMessage } from "react-intl";
import { baseUrl, locale, percentage_earned } from "../../constantes/constantes";
import messages from "../../locales/messages";
import PaymentMethod from "../payment/PaymentMethod";
import axios from "axios";
import Spinner from "../common/Spinner";
import MessageModal from "../common/MessageModal";
import { connect } from "react-redux";
import { hideConfirmationModal, showConfirmationModal } from "../../actions/confirmation";
import { updateScheduleStep, updateOrder } from "../../actions/schedule";


const msg = messages[locale.toLowerCase().split(/[_-]+/)[0]];

class PlaceOrderRework extends Component {

    constructor(props) {
        super(props);
        let user = JSON.parse(localStorage.getItem('user'));
        let finalOrder = JSON.parse(localStorage.getItem('finalOrder'));
        let schedule = JSON.parse(localStorage.getItem('scheduel'));
        let restaurantItemStorage = JSON.parse(localStorage.getItem('restauItemStorage'));
        let provider = null;
        if (user.payment1_provider_data) provider = 1;
        if (!user.payment1_provider_data && user.payment2_provider_data) provider = 2;
        let useOrLoseAmount = null;

        if (schedule && restaurantItemStorage) {
            let meal = null;
            if (restaurantItemStorage.meals.length > 1) {
                meal = schedule.meal.toLowerCase() === "lunch" ? restaurantItemStorage.meals[0] : restaurantItemStorage.meals[1];
            } else {
                meal = restaurantItemStorage.meals[0];
            }

            if (meal && meal.dining_type === 2 && meal.orders_count === 0) {
                // useOrLoseAmount = meal.amount
                useOrLoseAmount = meal.amount + restaurantItemStorage.bonus
            }
        }

        this.state = {
            user: user ? user : null,
            finalOrder,
            schedule,
            restaurantItemStorage,
            promotion: null,
            provider,
            subtotal: 0,
            total: 0,
            tax: 0,
            tymeCash: user.tyme ? 1 : null,
            earning: 0,
            show: false,
            notes: '',
            loading: false,
            message: '',
            withCancel: false,
            useOrLoseAmount
        };
    }

    calculateOrdersPricing = () => {
        const { finalOrder, schedule, promotion, tymeCash, user, provider } = this.state;
        const { global_promotions, merchant_promotions } = schedule.merchant;
        const mergedPromotions = global_promotions.concat(merchant_promotions);
        const selectedPromo = mergedPromotions.filter(element => element.id === promotion)[0];
        let subtotal = 0,
            merchantTax = schedule.merchant.merchant.tax,
            total = 0,
            earning = 0,
            tax = 0;

        finalOrder.forEach(order => {
            let applyTax = false;
            let productSubtotal = 0;

            if (selectedPromo && selectedPromo.type) {
                if (selectedPromo.amount / 100 <= 50) productSubtotal += (order.price - (order.price * (selectedPromo.amount / 10000))) * order.quantity;
            } else {
                productSubtotal += order.price * order.quantity;
            }

            applyTax = !order.tax_exempt;

            if (order.modifiers) {
                order.modifiers.forEach(modifier => {

                    if (!applyTax) applyTax = !!modifier.force_tax;

                    productSubtotal += modifier.price * order.quantity;
                });
            }

            if (applyTax) tax += productSubtotal * (merchantTax / 100);

            subtotal += productSubtotal;
        });

        if (selectedPromo && !selectedPromo.type) {
            if ((subtotal / 2) > selectedPromo.amount) {
                tax -= (tax * (selectedPromo.amount / subtotal));
                subtotal = subtotal - selectedPromo.amount;
            }
        }

        total = tax + subtotal;

        if (tymeCash) {
            if (user.tyme < total && provider) {
                earning = (total - user.tyme) * percentage_earned;
            } else {
                earning = 0;
            }
        } else {
            if (provider) {
                earning = total * percentage_earned;
            }
        }

        this.setState({ subtotal, total, tax, earning });
    };

    componentDidMount() {
        this.calculateOrdersPricing();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prev_promotion = prevState.promotion;
        const prev_finalOrder = prevState.finalOrder;
        const prev_tymeCash = prevState.tymeCash;
        const prev_provider = prevState.provider;
        const { promotion, finalOrder, tymeCash, provider } = this.state;

        if (prev_promotion !== promotion || prev_finalOrder.length !== finalOrder.length || prev_tymeCash !== tymeCash || provider !== prev_provider) {
            this.calculateOrdersPricing();
        }
    }

    handleChange = e => {
        if (e.target.name === "provider") {
            this.setState({
                [e.target.name]: parseInt(e.target.value)
            });
        } else {
            this.setState({
                [e.target.name]: e.target.checked ? parseInt(e.target.value) : null
            });
        }
    };

    renderPaymentMethods = () => {
        if (this.state.user) {
            const { payment1_provider_data, payment2_provider_data } = this.state.user;
            return (
                <div>
                    {this.renderCardElement(payment1_provider_data, 1)}
                    {this.renderCardElement(payment2_provider_data, 2)}
                </div>
            );
        }

        return null
    };

    renderUseOrLoseMessage = () => {
        const { restaurantItemStorage, user, schedule } = this.state;

        if (schedule && restaurantItemStorage) {
            let meal = null;
            if (restaurantItemStorage.meals.length > 1) {
                meal = schedule.meal.toLowerCase() === "lunch" ? restaurantItemStorage.meals[0] : restaurantItemStorage.meals[1];
            } else {
                meal = restaurantItemStorage.meals[0];
            }
            let message = msg['payment.use_or_lose'].replace('%amount', formatMoney((meal.amount + restaurantItemStorage.bonus) / 100));

            if (user && user.corporate) message = message.replace('%corporate', user.corporate.name);

            if (meal && meal.dining_type === 2 && meal.orders_count === 0) {
                return (
                    <p className="text-muted">${message}</p>
                );
            }
        }

        return null;
    };

    renderCardElement = (paymentProvider, provider) => {
        return (
            paymentProvider ?
                <div className="content-spaced-between mb-2">
                    <label htmlFor={provider}><i class="fas fa-credit-card"></i> {`${paymentProvider.type} - XXXX XXXX XXXX ${paymentProvider.last4}`}
                    </label>
                    <input name="provider" id={provider} value={provider} type="checkbox"
                        checked={this.state.provider === provider}
                        onChange={this.handleChange}
                    />
                </div>
                :
                null
        );
    };

    renderPromotions = () => {
        const { global_promotions, merchant_promotions } = this.state.schedule.merchant;
        const mergedPromotions = global_promotions.concat(merchant_promotions);

        return mergedPromotions.map(promotion => {
            return (
                <div key={promotion.id} className="content-spaced-between mb-2">
                    <label
                        htmlFor={promotion.id}>{promotion.type ? `${promotion.amount / 100}% Off` : `$${formatMoney(promotion.amount / 100)} Off`}</label>
                    <input type="checkbox" name="promotion" id={promotion.id} value={promotion.id}
                        checked={this.state.promotion === promotion.id}
                        onChange={this.handleChange} />
                </div>
            );
        });
    };

    renderOrdersList = () => {
        return this.state.finalOrder.map((element, index) => {
            return (
                <div key={element.id + '_' + index} className="mb-4">
                    <div className="content-spaced-between">
                        <a className="light-blue-text" data-index={index} onClick={this.editSelectedOrder} href="/#">
                            {firstToUpperCase(element.title)}
                        </a>
                        <span><a className="delete-order-link" onClick={this.deleteSelectedOrder}
                            data-id={index} href="/#"><i class="fa fa-trash" data-id={index} aria-hidden="true"></i>
                        </a></span>
                    </div>
                    <div className="content-spaced-between light-blue-text">
                        <span style={{ color: '#fd4b95' }}>Quantity x {element.quantity}</span>
                        <span>$ {formatMoney(element.price / 100)}</span>
                    </div>
                    <div className="mb-2" style={{ borderBottom: '1px solid #f5f5f5' }}>
                        {element.modifiers ? <FormattedMessage id="orders.modifiers" /> : null}
                    </div>
                    {this.renderModifiersList(element.modifiers)}
                </div>
            );
        });
    };

    renderModifiersList = modifiers => {
        if (modifiers) {
            return modifiers.map(modifier => {
                return (
                    <div key={modifier.id} className="content-spaced-between">
                        <span>{modifier.name}</span>
                        <span>$ {formatMoney(modifier.price / 100)}</span>
                    </div>
                );
            });
        }
        return null
    };

    handleAddOtherOrder = () => {
        const { schedule } = this.state;
        if (schedule) {
            this.props.updateScheduleStep(1);
        }
    };

    handleCardsModal = () => {
        let user = JSON.parse(localStorage.getItem('user'));
        let provider = null;
        if (user.payment1_provider_data) provider = 1;
        if (user.payment2_provider_data) provider = 2;
        this.setState({ show: false, user, provider });
    };

    handleCheckout = (check = true) => {
        const { finalOrder, user, schedule, provider, tymeCash, notes, restaurantItemStorage, promotion, useOrLoseAmount, total } = this.state;

        let cardRequired = false;
        let proceed = true;

        if ((!tymeCash && !provider && !useOrLoseAmount) || (useOrLoseAmount && tymeCash && (user.tyme + useOrLoseAmount < total) && !provider) || (!provider && !tymeCash && useOrLoseAmount && useOrLoseAmount < total) || (!useOrLoseAmount && tymeCash && !provider && user.tyme < total)) { //1
            cardRequired = true;
            this.setState({ show: true });
        }

        if ((!provider && useOrLoseAmount && useOrLoseAmount >= total) || (tymeCash && user.tyme >= total && !provider)) {
            cardRequired = false;
        }

        if ((check && (useOrLoseAmount && tymeCash && user.tyme + useOrLoseAmount < total && provider)) || (check && (!useOrLoseAmount && tymeCash && user.tyme < total))) {
            proceed = false;

            let message = msg['payment.not_enough_tyme_cash']
                .replace('%amount%', formatMoney(user.tyme / 100))
                .replace('%remaining%', formatMoney((total - (user.tyme + useOrLoseAmount)) / 100));

            this.setState({ message, withCancel: true });
        }

        if (finalOrder && !cardRequired && proceed) {
            const { global_promotions, merchant_promotions } = schedule.merchant;

            this.setState({ loading: true });

            let products = [];

            finalOrder.forEach((order, index) => {
                let obj = {};

                if (index === 0) obj.user_id = user.id;

                obj.id = order.id;
                obj.quantity = order.quantity;

                if (order.modifiers) {
                    let array = [];
                    order.modifiers.forEach(modifier => array.push(modifier.id));
                    obj.modifiers = array;
                }
                products.push(obj);
            });

            let formData = new FormData();
            let meal = null;
            if (restaurantItemStorage.meals.length > 1) {
                meal = schedule.meal.toLowerCase() === "lunch" ? restaurantItemStorage.meals[0] : restaurantItemStorage.meals[1];
            } else {
                meal = restaurantItemStorage.meals[0];
            }
            formData.append('meal_id', meal ? meal.id : null);
            formData.append('in', '0');
            if (!(useOrLoseAmount && useOrLoseAmount >= total)) {
                formData.append('payment_method_number', provider);
            }
            formData.append('pay_with_tyme', tymeCash ? '1' : '0');
            if (notes) formData.append('additional_notes', notes);

            for (let i = 0; i < products.length; i++) {
                formData.append(`products[${i}][id]`, products[i].id);
                formData.append(`products[${i}][quantity]`, products[i].quantity);

                if (products[i].modifiers) {
                    products[i].modifiers.forEach((modifier, index) => {
                        formData.append(`products[${i}][modifiers][${index}]`, modifier);
                    });
                }
            }

            if (promotion) {

                if (global_promotions && global_promotions.filter(item => item.id === promotion).length) {
                    formData.append('global_promotion_id', promotion);
                }


                if (merchant_promotions && merchant_promotions.filter(item => item.id === promotion).length) {
                    formData.append('promotion_id', promotion);
                }
            }
            let config = {
                method: 'post',
                url: `${baseUrl}/api/merchant/${schedule.merchant_id}/order`,
                timeout: 20000,
                headers: { "Content-Type": "application/json", "Authorization": `Bearer ${user.token}` },
                data: formData
            };

            axios(config)
                .then(res => {
                    const { order } = res.data.data;
                    localStorage.setItem('orderId', order.id);
                    resetLocalStorage();
                    history.push(`/confirmationPage`);
                })
                .catch(error => {
                    const { status } = error.response;
                    switch (status) {
                        case 401:
                            this.showUnauthorizedModal();
                            break;
                        case 404:
                        default:
                            this.setState({ message: msg['payment.add_payment_card_server_error'] });
                    }
                })
                .finally(() => {
                    this.setState({ loading: false });
                });
        }
    };

    deleteSelectedOrder = e => {
        e.preventDefault();
        const $index = parseInt(e.target.getAttribute("data-id"));
        let { finalOrder } = this.state;
        const array = finalOrder.filter((element, index) => index !== $index);
        let message = msg['confirm.delete_product'];
        if (array.length < 1) message = msg['confirm.delete_order'];
        this.props.showConfirmationModal(
            message,
            () => {
                localStorage.setItem('finalOrder', JSON.stringify(array));
                this.setState({ finalOrder: array });
                if (array.length < 1) {
                    this.handleAddOtherOrder();
                }
                this.props.hideConfirmationModal();
            },
            () => {
                this.props.hideConfirmationModal();
            }
        );
        localStorage.removeItem('productIndex')
    };

    editSelectedOrder = e => {
        e.preventDefault();
        const $index = parseInt(e.target.getAttribute("data-index"));
        if (typeof $index === "number") this.props.updateOrder(2, $index);
        localStorage.setItem('productIndex', $index)
    };

    showUnauthorizedModal = () => {
        this.setState({ loading: false });
        this.props.showConfirmationModal(
            msg['error.unauthorized'],
            () => {
                localStorage.clear();
                history.push('/login');
                this.props.hideConfirmationModal();
            }, null);
    };
    renderDiscount = () => {
        let scheduel = JSON.parse(localStorage.getItem('scheduel'))
        if (scheduel.merchant.global_promotions.length || scheduel.merchant.merchant_promotions.length) {
            return <FormattedMessage id="orders.discount" />
        }
    }
    render() {
        return (
            <div className="container" style={{ padding: "8% 0%" }}>
                <div className="flavour-form">
                    <div className="merchantBody-title">
                        <FormattedMessage id="orders.your_order" />
                    </div>
                    <div className="order-container mb-5">
                        {this.renderOrdersList()}
                    </div>
                    
                    <div className="merchantBody-title">
                        {this.renderDiscount()}
                    </div>

                    <div className="mb-5">
                        {this.renderPromotions()}
                    </div>

                    <div className="merchantBody-title">
                        <FormattedMessage id="orders.additional_instructions" />
                    </div>
                    <div className="mb-5">
                        <textarea className="form-control orderNote"
                            value={this.state.notes}
                            onChange={e => this.setState({ notes: e.target.value })} />
                    </div>

                    <div className="merchantBody-title content-spaced-between">
                        <FormattedMessage id="payment.payment_title" />
                        <button className="border-0" onClick={() => this.setState({ show: true })} style={{ color: "#03a9f4" }}>
                            <i class="fas fa-pencil-alt"></i>
                        </button>
                    </div>

                    <div className="mb-5">
                        {this.renderPaymentMethods()}
                    </div>

                    <div className="mb-5">
                        <label className={this.state.tymeCash ? 'cash-label-selected' : 'cash-label'}
                            htmlFor="tymeCash">
                            <FormattedMessage id="orders.user_tyme_cash" />
                            <span>{this.state.user ? ` $ ${formatMoney(this.state.user.tyme / 100)}` : ''}</span>
                        </label>
                        <input hidden name="tymeCash" id="tymeCash" value="1" type="checkbox"
                            onChange={this.handleChange} />
                    </div>
                    <div className="content-spaced-between" style={{ fontFamily: 'Avenir Black' }}>
                        <FormattedMessage id="orders.subtotal" />
                        <span>{`$ ${formatMoney(this.state.subtotal / 100)}`}</span>
                    </div>
                    <div className="content-spaced-between" style={{ fontFamily: 'Avenir Black' }}>
                        <span>Tax</span>
                        <span>{`$ ${formatMoney(this.state.tax / 100)}`}</span>
                    </div>
                    <div className="text-center merchantBody-title mb-5 mt-5" style={{ fontFamily: 'Avenir Black' }}>
                        <span>Total: {`$ ${formatMoney(this.state.total / 100)}`}</span>
                        <p className="text-primary">{this.state.earning ? msg['payment.payment_earning'].replace('%', formatMoney(this.state.earning / 100)) : ''}</p>
                        {this.renderUseOrLoseMessage()}
                    </div>
                    <div className="content-spaced-between">
                        <button onClick={this.handleAddOtherOrder} className="pink-button">
                            <FormattedMessage id="orders.add_another_order" />
                        </button>
                        <button onClick={this.handleCheckout} className="blue-button">
                            <FormattedMessage id="orders.checkout" />
                        </button>
                    </div>
                </div>

                <div className={`modal fade show ${this.state.show ? 'modal-visible' : 'modal-hidden'}`} tabIndex="-1"
                    role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable"
                        role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <button onClick={this.handleCardsModal} type="button" className="close"
                                    data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <PaymentMethod />
                            </div>
                        </div>
                    </div>
                </div>
                <Spinner loading={this.state.loading} />
                <MessageModal withCancel={this.state.withCancel}
                    show={this.state.message}
                    message={this.state.message}
                    handleCancelAction={() => this.setState({ message: null, withCancel: false })}
                    handleCloseModal={() => {
                        if (this.state.withCancel) this.handleCheckout(false);
                        this.setState({ message: null, withCancel: false });
                    }} />

            </div>
        );
    }

}

export default connect(null, { showConfirmationModal, hideConfirmationModal, updateScheduleStep, updateOrder })(PlaceOrderRework);