import React, { Component } from 'react';
import GoogleLogin from 'react-google-login';
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png'
import { googleClientId } from '../../constantes/constantes'
import Background from '../../assets/background.png'
import history from '../../history'
import { FormattedMessage } from 'react-intl';




var choiceStyle = {
  backgroundImage: `url(${Background})`

};
class RegisterChoice extends Component {
componentDidMount=()=>{
  localStorage.removeItem('googleResponse')
}
  render() {
    const responseGoogle = (response) => {
      let googleResponse=JSON.parse(localStorage.getItem('googleResponse'))
      let user=JSON.parse(localStorage.getItem('googleResponse'))
      if(googleResponse && !user.first_name && !user.last_name){
        history.push('/google-register3')
      }
      else{
      localStorage.setItem('googleResponse',JSON.stringify(response))
      history.push('/google-register1')
    }
    }
    return (
      <div className="registerChoice" style={choiceStyle}>
        <div className="registerChoice-container">
          <div className="registerChoice-header">
            <img src={logo} alt="" />
          </div>
          <p className="registerChoice-text">ENTERPRISE FOOD PROGRAM</p>
          <GoogleLogin
            clientId={googleClientId}
            render={renderProps => (
              <button className="registerChoice-btn registerChoice-btn-google"
                onClick={renderProps.onClick} disabled={renderProps.disabled}
              >
                <i class="fab fa-google"></i><span> <FormattedMessage id='register.choice.google'/></span>
              </button>
            )}
            onSuccess={(response) => { responseGoogle(response) }}
            onFailure={() => { this.googleFailureAlert() }}
          >
           <i class="fab fa-google"></i>
            <span> <FormattedMessage id='register.choice.google'/></span>
          </GoogleLogin>
          <br />

          <Link to="/register1">
            <button
              className="registerChoice-btn registerChoice-btn-email "
            >
              <i class="fas fa-envelope" style={{marginRight:'1%',marginLeft:'-4%'}}></i><FormattedMessage id='register.choice.email'/>
        </button>
          </Link>
          <div style={{textAlign:'center'}}><p className="registerChoice-policy"><FormattedMessage id='registerChoice.policy1'/><a href="http://tyme.ai/privacy-policy/"  target="_new"><FormattedMessage id='registerChoice.policy2'/></a> <FormattedMessage id='register.and'/> <a href="http://tyme.ai/terms-of-service/"  target="_new"><FormattedMessage id='registerChoice.policy4'/></a></p></div>
        </div>
      </div>
    )
  }
}

export default RegisterChoice;