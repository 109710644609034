export const googleClientId = "17720708240-vbvupvptomjjpbib14gn0n04bjhtgqgv.apps.googleusercontent.com";
export const baseApiUrl = 'https://admin.tyme.ai/api';
export const baseUrl = 'https://admin.tyme.ai';
export const stripeApiKey = 'pk_live_i1aymrUaFvR4pXiLMvMMFb7Y';
export const locale = 'en-US';
export const percentage_earned = 0.03;

export const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user"
};
