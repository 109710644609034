import { GET_ERRORS } from '../actions/types';

const initialState = {
    errors: {},
    errorsStatus: ""
};

export default function (state = initialState, action) {
    switch (action.type) {
        case GET_ERRORS:
            return {
                errors: action.payload,
                errorStatus: action.errorStatus
            }
        default:
            return state;
    }
}