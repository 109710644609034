import React, { Component } from 'react';
import { connect } from "react-redux"
import { Container } from "react-grid-system"
import { FormattedMessage } from 'react-intl'

import { fetch_merchant } from '../../actions/merchants'
import { updateScheduleStep } from '../../actions/schedule'
import MerchantHeader from './MerchantHeader'
import Spinner from '../common/Spinner'
import { baseUrl } from '../../constantes/constantes'
import ItemMenu from './ItemMenu'
import MessageModal from '../common/MessageModal';

class MerchantRework extends Component {

    constructor(props) {
        super(props);
        this.state = {
            meal: "",
            delivery_time: "",
            choice: "",
            show: false,
        }
    }

    handleChange = (event) => {
        this.setState({
            choice: event.target.value,

        });
    };
    HandleMenuChoice = () => {
        const { merchant, loading } = this.props;
        let myRestauItemStorage = localStorage.getItem('restauItemStorage');
        let restauItemStorage = JSON.parse(myRestauItemStorage);
        let scheduel = {
            meal: this.state.meal,
            merchant_id: restauItemStorage.merchant_id,
            photo: restauItemStorage.photo,
            productChoice: this.state.choice,
            delivery_time: this.state.delivery_time,
            merchant: merchant,
            loading: loading,
        };
        localStorage.setItem('scheduel', JSON.stringify(scheduel));
        if (merchant.auth) {
            let user = JSON.parse(localStorage.getItem('user'));
            if (user) {
                user.tyme = merchant.auth.tyme;
                localStorage.setItem('user', JSON.stringify(user));
            }
        }
        if (this.state.choice === "") {
            this.setState({
                show: !this.state.show
            })
        } else {
            this.props.updateScheduleStep(2);
        }
    };
    componentDidMount() {
        let restauItemStorage = JSON.parse(localStorage.getItem('restauItemStorage'));

        let token = localStorage.getItem('jwtToken');

        let data = {
            id: restauItemStorage.merchant_id,
            token: token
        };
        this.props.fetch_merchant(data);
        /**meal decision */
        let meals = restauItemStorage.meals;
        let choice = restauItemStorage.choice;

        if (meals && meals.length === 2) {
            this.setState({ meal: choice })
        } else if (meals && meals.length === 1) {
            if (meals[0].type === 1) {
                this.setState({ meal: "lunch" })
            } else this.setState({ meal: "dinner" })
        } else this.setState({ meal: 'No meals' });
        /**delivery time*/
        if (meals && meals.length === 2) {
            if (choice === "Lunch") {
                this.setState({ delivery_time: meals[0].delivery_time })
            } else this.setState({ delivery_time: meals[1].delivery_time })
        } else if (meals && meals.length === 1) {
            this.setState({ delivery_time: meals[0].delivery_time })
        } else this.setState({ delivery_time: "there is no delivery" });
        localStorage.removeItem('modifiersTab')
        localStorage.removeItem('currentProductId')
        localStorage.removeItem('stateRadioTab')
        localStorage.removeItem('productIndex')
    }
    renderMerchant = () => {
        let restauItemStorage = JSON.parse(localStorage.getItem('restauItemStorage'));
        let photo = restauItemStorage.photo;
        const { merchant } = this.props;
        const { delivery_time, meal } = this.state;
        if (!merchant) {
            return (
                <div className='container-fluid' style={{
                    backgroundColor: "#f2f2f2", height: "100vh",
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",

                }}>
                    <div style={{
                        position: "relative",
                        bottom: "5%",
                        fontFamily: 'Avenir Medium'
                    }}>
                        {!this.props.loading ? <FormattedMessage id="home.not_found" /> : null}
                    </div>
                </div>
            );
        } else {
            return (
                <Container className="MerchantPage">
                    < MerchantHeader name={merchant !== undefined ? merchant.merchant.name : ''}
                        description={merchant !== undefined ? merchant.merchant.description : ''}
                        logo={merchant !== undefined ? baseUrl + '/' + merchant.merchant.logo : ''}
                        delivery_time={delivery_time}
                        meal={meal}
                        photo={photo} />
                    <div className="merchantBody">
                        <div className="merchantBody-container">
                            <div className="merchantBody-title">< FormattedMessage id="home.merchantBody.restaurant_menu" />
                            </div>
                            < div>
                                {
                                    merchant && merchant.merchant.menu.map((el, index) => < ItemMenu key={index}
                                        item={el}
                                        choice={this.state.choice}
                                        handleChange={this.handleChange}
                                    />)}
                            </div>
                            <button onClick={this.HandleMenuChoice} className="MerchantMenu-btn">
                                < FormattedMessage id="home.mechantBody.Add_to_Cart" />
                            </button>

                            <MessageModal show={this.state.show}
                                message={"Please choose a product"}
                                handleCloseModal={
                                    () => this.setState({ show: !this.state.show })
                                }
                            />
                        </div>
                    </div>
                </Container>
            );
        }
    }
    render() {
        const { loading } = this.props;
        return (
            <div>
                < Spinner loading={loading} />
                {this.renderMerchant()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { merchant, loading } = state.merchant;
    return { merchant, loading }
};

export default connect(mapStateToProps, { fetch_merchant, updateScheduleStep })(MerchantRework);