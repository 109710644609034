
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {addLocaleData, IntlProvider} from "react-intl";

import en from "react-intl/locale-data/en";
import fr from "react-intl/locale-data/fr";

import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

/*import 'moment/locale/fr';*/

import App from './App';
import messages from './locales/messages';
import {locale} from "./constantes/constantes";

addLocaleData([...en, ...fr]);

/*const language = (navigator.languages && navigator.languages[0]) || navigator.language || locale;*/

const languageWithoutRegionCode = locale.toLowerCase().split(/[_-]+/)[0];

ReactDOM.render(
    <IntlProvider locale={languageWithoutRegionCode} messages={messages[languageWithoutRegionCode]}>
        <App/>
    </IntlProvider>,
    document.getElementById('root')
);

