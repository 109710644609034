import React, { Component } from 'react';
import { connect } from 'react-redux';
import Webcam from "react-webcam";
import { Link } from 'react-router-dom'
import Background from '../../assets/background.png'
import logo from '../../assets/logo.png'
import { registerUser } from '../../actions/authentication'
import Spinner from '../../components/common/Spinner'
import MessageModal from '../common/MessageModal'
import history from '../../history'
import { FormattedMessage } from 'react-intl';

class RegisterStep4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            imagePreviewUrl: '',
            file: '',
            webcamEnabled: false,
            screenshot: null,
            show: false,
            shown: false,
            allowCam: false,
            viewModal: false,
            Go_again_Buttons: false,
            uploadFile_Button: true,
            iconCam: true,
            errors: {},
        }
    }

    /***for file upload */
    handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                imagePreviewUrl: reader.result,
                file: file,
                iconCam: false,
                enableWebcam: true,
                uploadFile_Button: false,
                Go_again_Buttons: true,
                webcamEnabled: false
            });
        }
        reader.readAsDataURL(file)
    }
    /**webcam**/
    enableWebcam = () => {
        this.setState({
            viewModal: true
        })
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then(() => this.setState({  allowCam: true,  webcamEnabled: true })
            );

    };
    setRef = webcam => {
        return this.webcam = webcam;
    }
   
    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    }
    ///*convert base64 to image"*/
    dataURLtoFile(dataurl, filename) {
     // test browser edge
        var isIE = /*@cc_on!@*/false || !!document.documentMode;
        var isEdge = !isIE && !!window.StyleMedia;
     /***/   
        var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        if(isEdge){
           return new Blob([u8arr], filename, { type: mime });   
        } else{
            return new File([u8arr], filename, { type: mime });  
        }
      
    }

    capture = () => {
        const imageSrc = this.webcam.getScreenshot();
        this.setState({
            screenshot: imageSrc,
            webcamEnabled: false,
            Go_again_Buttons: true,
            uploadFile_Button: false,
            iconCam: false,
            enableWebcam: false,
            url: ''
        })
    }

    /***webcam*/
    takeOtherPicture = () => {
        this.setState({
            uploadFile_Button: true,
            Go_again_Buttons: false,
            iconCam: true
        })
        // this.handleUserMedia()
    }
    changeBakground = () => {
        let url = ""
        if (this.state.iconCam === true) {
            url = ""
        } else if (this.state.enableWebcam === true) {
            url = `${this.state.imagePreviewUrl}`
        } else {
            url = `${this.state.screenshot}`
        }
        return url
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.errors) {
            this.setState({
                errors: nextProps.errors
            });
        }
    }
    componentDidMount() {
        let user = localStorage.getItem('user');
        let myuser = JSON.parse(user)
        let token = localStorage.getItem('jwtToken');
        if (myuser && token) {
            if (myuser.first_name && myuser.last_name) {
                //test if user have a profile picture
                if (user.profile) {
                    this.props.history.push('/');
                } else {
                    history.push('/register4')
                }
            } else {
                history.push('/register3')
            }
        }
    }
componentWillUnmount(){
/****to shutdown the webcam */
    window.location.reload()
}  
    register = () => {
        let token = localStorage.getItem('jwtToken')
        const data = {
            token: token,
            profile: this.dataURLtoFile(this.changeBakground(), 'picture')
        }
        this.props.registerUser(data);
    }

    render() {
        const videoConstraints = {
            width: 1280,
            height: 720,
            facingMode: "user"
        };
        const registerStyle = {
            backgroundImage: `url(${Background})`,
            position: 'relative',
            zIndex: '9'
        };
        return (
            <div className="registerPage" style={registerStyle}>
                <div className="registerSkip"><Link to="/"><FormattedMessage id='register.skip'/></Link></div>
                <div className="registerPage-header">
                    <img src={logo} alt="" />
                </div>
                <div className="registerPage-text">
                    <p>ENTERPRISE FOOD PROGRAM</p>
                </div>
                <div className="registerStep5">
                    <Spinner loading={this.props.auth.loading} />
                    <div className="registerStep5-text">
                        <p><FormattedMessage id='register.take.profile.picture'/></p>
                    </div>
                    <div className="registerPictureDiv">
                        {this.state.iconCam && <div
                            className="registerPictureZone"
                            style={{ display: this.state.webcamEnabled ? 'none' : 'block' }}
                            onClick={() => {
                                this.enableWebcam()
                            }}
                        >
                            <i class="fas fa-camera fa-3x"style={{color:'#fff',marginTop:'35%'}}></i>
                        </div>}
                        {!this.state.iconCam && <div
                            className="registerPictureZone"
                            style={{ backgroundImage: `url(${this.changeBakground()})`, backgroundPosition: 'center center' }}
                        >
                        </div>}
                        {this.state.webcamEnabled &&
                            <div className="registerStep5-cam" style={{ display: this.state.webcamEnabled ? 'block' : 'none' }}>
                                <Webcam
                                    audio={false}
                                    height={280}
                                    ref={this.setRef}
                                    screenshotFormat="image/jpeg"
                                    width={320}
                                    videoConstraints={videoConstraints}
                                />
                                <button style={{ display: this.state.allowCam ? "block" : "none" }} onClick={() => {
                                    this.capture()
                                }}> <FormattedMessage id='register.take.picture'/>
                            </button>
                            </div>
               }
                    </div>
                    <div className="registerStep5-uploadButton">
                        {this.state.uploadFile_Button &&
                            <label id="registerFileInput"> <FormattedMessage id='register.upload'/>
                            <input
                                    className="registerFileInput"
                                    type="file"
                                    onChange={(e) => this.handleImageChange(e)}
                                />
                            </label>
                        }
                    </div>
                    {this.state.Go_again_Buttons &&
                        <div className="registerStep5-btn">
                            <button onClick={this.takeOtherPicture}><FormattedMessage id='register.take.again'/></button>
                            <button onClick={this.register}><FormattedMessage id='register.let.go'/></button>
                        </div>
                    }
                    <MessageModal show={(this.state.viewModal && !this.state.allowCam) ? true : false}
                        message='Please activate your Webcam'
                        handleCloseModal={() => this.setState({ viewModal: false})} />

                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors.errors,

});
export default connect(mapStateToProps, { registerUser })(RegisterStep4);