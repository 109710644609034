import React, { Component } from 'react';
import { Container, Row, Col } from 'react-grid-system'
import { FormattedMessage } from "react-intl";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import { updateScheduleStep, cancelEdit } from "../../actions/schedule";
import { firstToUpperCase } from '../../constantes/helpers'

class MerchantHeader extends Component {

    cartProductsHandler = () => {
        let products = JSON.parse(localStorage.getItem('finalOrder'))
        if (products) {
            return products.length
        } else {
            return 0
        }
    }
    cartLink = (e) => {
        e.preventDefault()
        if (this.cartProductsHandler() > 0) {
            this.props.updateScheduleStep(3)
            this.props.cancelEdit()
        }
        else {
            this.props.updateScheduleStep(1)
        }
    }

    render() {
        const { name, description, logo, meal, photo, delivery_time } = this.props
        const style = {
            backgroundImage: `url(${photo})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition:'center center'
        }

        return (
            <Container className="merchantHeader">
                <Row>
                    <Col className="merchantPhoto" lg={3} style={style}>
                    </Col>
                   <div className='merchantLogo-container'><img className="merchantLogo"src={logo} alt="merchant logo"></img></div> 
                    <Col className="merchantParagraph" lg={6}>
                        <p className="merchantName">{name} <span
                            className="merchantMeal">- {firstToUpperCase(meal)}</span></p>
                        <div className="merchantDescription">
                            {
                                description.toString().includes("\n") ?

                                    <p>{description.toString().split("\n")[0]}<br /><br />{description.toString().split("\n")[2]}</p>
                                    :
                                    <p>{description}</p>

                            }
                        </div>
                        <p className="DeliveryTime"><FormattedMessage
                            id='home.merchantHeader.time_delivery' /> : {delivery_time} </p>
                    </Col>
                    <Col onClick={this.cartLink} className={this.cartProductsHandler() !== 0 ? "userCartPink" : "userCart"} lg={2}>
                        <Link to='/schedule'>
                            <i class="fas fa-shopping-cart fa-3x" style={{ marginBottom: '9%',marginTop:'17%' }} ></i>
                            <p>
                                {`(${this.cartProductsHandler()})`} <FormattedMessage id='home.merchantHeader.item_in' />
                                <br /><FormattedMessage id='home.merchantHeader.Food_Cart' />
                            </p>
                        </Link>
                    </Col>
                </Row>
            </Container>);
    }
}

export default connect(null, { updateScheduleStep, cancelEdit })(MerchantHeader);